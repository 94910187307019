import supabase from "./supabase";
import { getSelectedCourse } from "./courses"; // Ensure this import is correct based on your project structure

export const getUserRegisteredCourses = async (user_id) => {
  try {
    const { data, error } = await supabase
      .from("user_batch")
      .select("*")
      .eq("user_id", user_id);

    if (error) {
      throw error;
    }

    const coursePromises = data.map(async (course) => {
      return await getSelectedCourse(course.course_id);
    });

    const courses = await Promise.all(coursePromises);

    return courses;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getCurrentModule = async (course_id) => {
  try {
    const { data, error } = await supabase
      .from("user_batch")
      .select("current_module")
      .eq("course_id", course_id);

    if (error) {
      throw error;
    }
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
