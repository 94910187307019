import { faFilePdf, faFileZipper } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input } from "antd";
import React from "react";
import HomeHeader from "../../components/header/homeHeader";

export default function AssingmentDetails() {
  return (
    <>
      <div className="w-full flex justify-center mt-10">
        <div className="flex flex-col w-3/6 gap-5">
          <span className="text-pop md:text-xl text-lg">#123</span>

          <div className="course-box flex flex-col">
            <span className="text-pop md:text-lg text-sm">
              <FontAwesomeIcon icon={faFileZipper} /> First Java Program.zip
            </span>
            <span className="text-pop md:text-lg text-sm">
              <FontAwesomeIcon icon={faFilePdf} /> First Java Program.pdf
            </span>
          </div>
          <span className="text-pop md:text-xl text-lg">Description</span>
          <span className="text-pop md:text-sm text-xs">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.{" "}
          </span>
          <Input.TextArea placeholder="Remarks" />
          <div className="flex flex-row gap-3">
            <Button>Pass</Button>
            <Button>Fail</Button>
          </div>
        </div>
      </div>
    </>
  );
}
