import React from "react";
import "../courses/activeTab.css";
import "./assingment.css";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider } from "antd";
import { faFileZipper } from "@fortawesome/free-solid-svg-icons";
export default function ViewDetails({ assingment }) {
  return (
    <>
      <div className="flex justify-center items-center">
        <div className="assingment-submit-box flex flex-col mt-3">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row items-center gap-3">
              <FontAwesomeIcon icon={faFile} />
              <div className="flex flex-col">
                <span className="active-box-heading">Assingment</span>
                <span className="active-box-subheading">
                  {assingment || "First program in Java"}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-start">
              <span className="active-lato">Deadline</span>
              <span
                className="active-lato"
                style={{
                  backgroundColor: "#DFDFDF",
                  padding: "10px 15px 10px 15px",
                  borderRadius: "10px",
                }}
              >
                14 Mar 11:59 PM
              </span>
            </div>
          </div>
          <Divider
            plain
            style={{ color: "#00000029", borderWidth: "2px" }}
          ></Divider>
          <div className="flex flex-row justify-center items-center gap-5">
            <Button
              style={{
                width: "100%",
                padding: "20px 0px 20px 0px",
                borderRadius: "10px",
              }}
              className="active-lato"
            >
              View Details
            </Button>
          </div>
          <div className="flex flex-row justify-start items-center gap-5 mt-5 p-2">
            <span className="text-lato">My Submissions</span>
          </div>
          <div className="flex flex-row justify-between items-center gap-5">
            <div className="flex flex-row gap-3 items-center p-4">
              <FontAwesomeIcon size="100px" icon={faFileZipper} />
              <span className="text-lato">my-first-java-program.zip</span>
            </div>
            <div className="flex flex-row gap-3">
              <Button className="remove text-lato">Remove</Button>
              <Button className="replace text-lato">Replace</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
