import React, { createContext, useContext, useState } from "react";

const CounterContext = createContext();

const CounterProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("user_cart_courses") || "[]") || []
  );
  const [wishItems, setWishItems] = useState(
    JSON.parse(localStorage.getItem("user_wishlist_courses") || "[]") || []
  );
  const [cartCount, setCartCount] = useState(cartItems.length);
  const [wishCount, setWishCount] = useState(wishItems.length);

  const addItem = (item, type) => {
    try {
      if (type === "cart") {
        cartItems.push(item);
        setCartCount(cartItems.length);
        localStorage.setItem("user_cart_courses", JSON.stringify(cartItems));
      } else {
        wishItems.push(item);
        setWishCount(wishItems.length);
        localStorage.setItem(
          "user_wishlist_courses",
          JSON.stringify(cartItems)
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const removeItem = (itemId, type) => {
    try {
      if (type === "cart") {
        const updatedCourses = cartItems.filter(
          (course) => course.id !== itemId
        );
        localStorage.setItem(
          "user_cart_courses",
          JSON.stringify(updatedCourses)
        );
        setCartItems(updatedCourses);
        setCartCount(updatedCourses.length);
      } else {
        const updatedCourses = wishItems.filter(
          (course) => course.id !== itemId
        );
        localStorage.setItem(
          "user_wishlist_courses",
          JSON.stringify(updatedCourses)
        );
        setWishItems(updatedCourses);
        setWishCount(updatedCourses.length);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const isExists = (id, type) => {
    try {
      if (type === "cart") {
        return cartItems.some((x) => x.id === id);
      } else {
        return wishItems.some((x) => x.id === id);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <CounterContext.Provider
      value={{
        cartCount,
        setCartCount,
        wishCount,
        setWishCount,
        addItem,
        removeItem,
        isExists,
        cartItems,
        wishItems,
      }}
    >
      {children}
    </CounterContext.Provider>
  );
};

export default CounterProvider;
export const useCounter = () => {
  return useContext(CounterContext);
};
