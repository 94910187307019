import supabase from "./supabase";

export const getCareers = async () => {
  try {
    const { data, error } = await supabase.from("careers").select("*");
    if (error) {
      console.error(error);
      return;
    }
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getSelectedCareer = async (id) => {
  try {
    const { data, error } = await supabase
      .from("careers")
      .select("*")
      .eq("id", `${id}`);
    if (error) {
      console.error(error);
      return;
    }
    return data;
  } catch (err) {
    console.error(err);
  }
};
