import React, { useRef, useState } from "react";
import { Button } from "antd";
import whoweare from "images/whoweare.png";
import "./home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import car1 from "images/car1.jpg";
import car2 from "images/car2.jpg";
import car6 from "images/car6.jpg";
import car3 from "images/car8.jpg";
import car4 from "images/car4.jpg";
import car5 from "images/ProjectBased.jpg";
export default function HomeIntro() {
	const videoRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);

	const handlePlay = () => {
		if (isPlaying) {
			videoRef.current.pause();
			setIsPlaying(false);
		} else {
			videoRef.current.play();
			setIsPlaying(true);
		}
	};

	return (
		<>
			<div>
				<div className="flex flex-col md:flex-row justify-center items-center mt-5">
					<span className="text-poppins md:text-7xl text-3xl mb-4 line-height">
						Empowering the{" "}
						<span className="mb-4" style={{ color: "#7FDB80" }}>
							Future
						</span>{" "}
						with Knowledge and Innovation
					</span>
					<div className="flex flex-col">
						<div className="flex w-full">
							<span className="text-poppins md:text-lg text-xs text-wrap flex flex-col gap-2">
								Welcome to the tech revolution where we empower
								students with project based learning of latest
								technologies.
								<br />- Join us and unleash your coding
								superpower
							</span>
						</div>
						<div className="flex w-full justify-center">
							<Button
								href="#explore"
								//onClick={navigateToEventInfo}
								className="text-lato justify-center w-3/5 mt-5"
								style={{
									borderColor: "black",
									boxShadow: "6px 8px 0px 4px #7FDB80",
								}}
							>
								Explore
							</Button>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-5 mt-10">
					<div className="relative">
						{/* <img
							className="w-full h-full md:h-1/2 rounded-lg"
							src={whoweare}
							alt="intro vid"
						/> */}
						<video
							src="https://upcdn.io/FW25c5x/raw/landing/Intro.mp4"
							className="w-full h-full md:h-1/4 rounded-lg"
							ref={videoRef}
							controls={isPlaying}
							onEnded={() => setIsPlaying(false)}
							onPause={() => setIsPlaying(false)}
						></video>
						{!isPlaying && (
							<Button
								onClick={handlePlay}
								className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-lato border-black shadow-[6px_8px_0px_4px_#7FDB80]"
							>
								<div className="px-1 bg-[#7FDB80] rounded-full">
									<FontAwesomeIcon
										icon={faPlay}
										color="white"
									/>
								</div>
								Who are we ?
							</Button>
						)}
					</div>
					<div className="grid grid-cols-2 md:flex md:flex-row justify-between md:gap-0 gap-4">
						<span className="flex flex-col items-center md:text-black text-pink-500 text-poppins md:text-xl text-lg">
							200+
							<span className="md:text-2xl text-sm text-black">
								Registered Schools
							</span>
						</span>
						<div
							className="md:flex hidden"
							style={{ width: "4px", backgroundColor: "#3970FF" }}
						></div>
						<span className="flex flex-col items-center md:text-black text-blue-500 font-bold text-poppins md:text-2xl text-lg">
							10000+
							<span className="md:text-2xl text-sm text-black">
								Enrolled Students
							</span>
						</span>
						<div
							className="hidden md:flex"
							style={{ width: "4px", backgroundColor: "#7BF27C" }}
						></div>
						<span className="flex flex-col items-center md:text-black text-green-500 text-poppins md:text-2xl text-lg">
							100+
							<span className="md:text-2xl text-sm text-black">
								Registered Colleges
							</span>
						</span>
						<div
							className="hidden md:flex"
							style={{ width: "4px", backgroundColor: "#FF9040" }}
						></div>

						<span className="flex flex-col items-center md:text-black text-orange-500 text-poppins md:text-2xl text-lg">
							20000+
							<span className="md:text-2xl text-sm text-black">
								Student Community
							</span>
						</span>
					</div>
				</div>
				<div className="flex flex-col mt-10 gap-3">
					<span className="text-poppins text-black md:text-2xl text-md flex flex-row justify-center">
						Wall of Recognition
					</span>
					<section class="md:p-4">
						<div class="container mx-auto md:px-5 py-2 lg:px-4">
							<a
								href="https://gallery.knowvationlearnings.in"
								rel="noreferrer"
								target="_blank"
							>
								<div class="-m-1 flex flex-wrap md:-m-2">
									<div class="flex w-1/2 flex-wrap">
										<div class="w-1/2 p-1 md:p-2">
											<img
												alt="gallery"
												height={420}
												width={720}
												class="block h-full w-full rounded-lg object-cover object-center"
												src={car2}
											/>
										</div>
										<div class="w-1/2 p-1 md:p-2">
											<img
												alt="gallery"
												height={420}
												width={720}
												class="block h-full w-full rounded-lg object-cover object-center"
												src={car3}
											/>
										</div>
										<div class="w-full p-1 md:p-2">
											<img
												alt="gallery"
												height={420}
												width={720}
												class="block h-full w-full rounded-lg object-cover object-center"
												src={car1}
											/>
										</div>
									</div>
									<div class="flex w-1/2 flex-wrap">
										<div class="w-full p-1 md:p-2">
											<img
												alt="gallery"
												height={420}
												width={720}
												class="block h-full w-full rounded-lg object-cover object-center"
												src={car6}
											/>
										</div>
										<div class="w-1/2 p-1 md:p-2">
											<img
												alt="gallery"
												height={420}
												width={720}
												class="block h-full w-full rounded-lg object-cover object-center"
												src={car5}
											/>
										</div>
										<div class="w-1/2 p-1 md:p-2">
											<img
												alt="gallery"
												class="block h-full w-full rounded-lg object-cover object-center"
												height={420}
												width={720}
												src={car4}
											/>
										</div>
									</div>
								</div>
							</a>
						</div>
					</section>
				</div>
			</div>
		</>
	);
}
