import React from "react";
import { Avatar, Button, Input, Tooltip } from "antd";
import profile from "../../images/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faLink } from "@fortawesome/free-solid-svg-icons";

export default function ChatPage({ sendMessage, name }) {
  const sendMessages = (text) => {
    //sendMessage(user, text);
    console.log("message");
  };
  return (
    <div className="flex flex-col justify-between">
      <div className="w-full flex flex-col justify-between gap-5 overflow-y-scroll">
        <div>
          <div className="flex flex-row justify-between p-3 w-full border-b-2 border-solid border-gray-400">
            <div className="flex flex-row gap-3 items-center">
              <Avatar
                icon={<img src={profile} alt="profile image" />}
                size={50}
              />
              <div className="flex flex-col">
                <span className="text-pop md:text-xl text-sm">Name</span>
                <span className="text-pop md:text-sm text-xs">role</span>
              </div>
            </div>
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </div>
          <div className="flex flex-col p-3 gap-4">
            <div
              className="flex w-3/6 flex-col rounded-lg border-2 p-2 border-gray-300"
              style={{ backgroundColor: "#EFEFEF" }}
            >
              <span className="text-wrap text-pop md:text-xl text-sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </span>
              <span className="text-pop flex w-full text-right justify-end">
                7:30 PM
              </span>
            </div>
            <div className="flex flex-row justify-end items-end">
              <div
                className="flex w-3/6 flex-col rounded-lg border-2 p-2 border-gray-300"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <span className="text-wrap text-pop md:text-xl text-sm">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </span>
                <span className="text-pop flex w-full text-right justify-end">
                  7:30 PM
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center flex-row justify-center gap-3 ">
        <Input.TextArea
          className="w-3/5"
          placeholder="Enter your message"
          onChange={(text) => sendMessages(text)}
          suffix={
            <Tooltip title="attac file">
              <FontAwesomeIcon icon={faLink} />
            </Tooltip>
          }
        />
        <Button style={{ backgroundColor: "#7BF27C" }}>Send</Button>
      </div>
    </div>
  );
}
