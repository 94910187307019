import React from "react";
import { Button } from "antd";
import liveicon from "../../../images/liveicon.png";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faTowerBroadcast,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
export default function All({
  iconname,
  title,
  subtitle,
  btnContent,
  btnColor,
  contentColor,
}) {
  const [link, setLink] = React.useState(title && title.toLowerCase() + "s");
  return (
    <>
      <div className="course-box flex flex-row mt-3 justify-between items-center">
        <div className="flex flex-row items-center gap-3">
          <FontAwesomeIcon icon={iconname || faTowerBroadcast} />
          <div className="flex flex-col">
            <span className="active-box-heading">{title || "Live Class"}</span>
            <span className="active-box-subheading">
              {subtitle || "First program in Java"}
            </span>
          </div>
        </div>
        <Link to={link}>
          <Button
            className="btn-active"
            style={{
              backgroundColor: btnColor || "#7BF27C",
              color: contentColor || "black",
            }}
          >
            {btnContent || "Sample"}
          </Button>
        </Link>
      </div>
    </>
  );
}
