import React from "react";
import "./courseDetails.css";
import {
  faTowerBroadcast,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Progress, Button } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import ActiveTab from "./activeTab";
export default function CourseBox({ setSelectActive, module }) {
  const [showTopics, setShowTopics] = React.useState(false);

  let bordercolor = "";
  const onBoxClick = () => {
    setSelectActive({ module_id: module.id });
  };
  return (
    <>
      <div
        onClick={onBoxClick}
        className="flex flex-col gap-2 mt-5 course-box items-start"
      >
        <span className="text-lato">
          Module {module.order} : {module.module_name}
        </span>

        <div
          className="flex flex-row justify-between"
          style={{ width: "100%" }}
        >
          <span className="text-poppins-ash"></span>
          <Button
            className="topics-btn"
            onClick={() => setShowTopics(!showTopics)}
          >
            {showTopics ? (
              <>
                Hide Topics <FontAwesomeIcon icon={faAngleUp} />
              </>
            ) : (
              <>
                Show Topics <FontAwesomeIcon icon={faAngleDown} />
              </>
            )}
          </Button>
        </div>
        {showTopics && (
          <>
            <div className="topics-div flex flex-col" style={{ width: "100%" }}>
              {module &&
                module.sub_modules.map((item) => (
                  <span className="text-poppins-ash flex gap-3">
                    <PlayCircleOutlined />
                    {item.sub_module_name}
                  </span>
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}
