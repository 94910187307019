import React from "react";
import recordimage from "../../../images/recording.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { Button } from "antd";

export default function Recording() {
  return (
    <>
      <div>
        <div className="mt-10">
          <span className="text-lato flex flex-col">
            👉 Recoding - First Java Program{" "}
          </span>
          <img
            src={recordimage}
            style={{ height: "40vh", width: "100%", objectFit: "contain" }}
            alt="recording image"
          />
        </div>
        <div className="flex flex-row justify-between items-center mt-5">
          <span className="text-lato">👉 First Java Program </span>
          <Button style={{ borderStyle: "none", backgroundColor: "#E1E1E1" }}>
            <FontAwesomeIcon icon={faCircleCheck} />
            <span>watched</span>
          </Button>
        </div>
        <div className="flex flex-col gap-3 mt-5">
          <span className="text-lato">Description</span>
          <span className="">
            We are seeking a highly motivated and tech-savvy Digital Marketing
            Intern to join our team. As a Digital Marketing Intern, you will
            have the opportunity to gain practical experience in various aspects
            of digital marketing, including social media management, content
            creation, campaign optimization, and data analysis. This internship
            provides a valuable opportunity to develop your skills in a
            fast-paced, results-driven environment while contributing to the
            success of our digital marketing initiatives.
          </span>
          <div className="flex justify-center">
            <Button
              className="text-lato"
              style={{
                width: "30%",
                padding: 10,
                borderColor: "black",
                boxShadow: "6px 8px 0px 4px #7BF27C",
              }}
            >
              Next class installation
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
