import {
  faCalendar,
  faClock,
  faClockFour,
  faHeart,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import DashboardHeader from "../../../components/header/dashboardHeader";
import {
  faIndianRupee,
  faRupee,
  faSignal,
  faStar,
  faInfinity,
  faCertificate,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";

import Avatar from "antd/es/avatar/avatar";
import profile from "../../../images/profile.png";
import coursetemp from "../../../images/coursetemp.png";
import { Button, Badge } from "antd";
import AboutCourse from "./aboutCourse";
import CourseContent from "./courseContent";
import BlackCard from "./blackCard";
import RatingAndReview from "./ratingAndReviews";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  getCourseModule,
  getSelectedCourse,
} from "../../supa-base-client/courses";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { useCounter } from "../../context/counterContext";
import HomeHeader from "../../../components/header/homeHeader";
import Card from "components/cards/card";
import { getCourseBatch } from "util/methods";

export default function CoursePage() {
  const location = useLocation();
  const { addItem, isExists, removeItem } = useCounter();
  let { course: courseProp } = location.state || {};
  const [course, setCourse] = React.useState(courseProp || {});
  const [courseBatch, setCourseBatch] = React.useState([]);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        if (!course || Object.keys(course).length === 0) {
          const result = await getSelectedCourse(id);
          setCourse(result[0]);
        }
        const batch = await getCourseBatch();
        setCourseBatch(batch);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCourse();
  }, [id]);

  const [courseModule, setCourseModule] = React.useState(null);
  const [iconColor, setIconColor] = useState("");
  const [cartbtnText, setCartBtnText] = useState("Add to cart");
  useEffect(() => {
    async function fetchModuleData() {
      const result = await getCourseModule(course.id, false);

      setCourseModule(result);
    }
    fetchModuleData();
  }, [course]);

  const [selected, setSelected] = React.useState("about");
  const navigate = useNavigate();
  const navigationBtn = (screen_name) => {
    setSelected(screen_name);
  };

  const navigateToPaymentPriview = () => {
    navigate("/Programs/info/courseinfo/payment");
  };

  const addToCart = (course) => {
    if (isExists(course.id, "cart")) navigate("/cart");
    else {
      addItem(course, "cart");
      setCartBtnText("Go to cart");
    }
  };

  const addToWishList = (course) => {
    if (!isExists(course.id, "wishlist")) {
      addItem(course, "wishlist");
      setIconColor("red");
    } else {
      removeItem(course.id, "wishlist");
      setIconColor("black");
    }
  };

  const [selectActive, setSelectActive] = React.useState("content");
  return (
    <div className="md:pl-5 md:pr-5 flex flex-col">
      {course && (
        <>
          <div className="flex flex-col mt-5 gap-2 w-full">
            <span className="text-lato md:text-xl">KNOWVATION LEARNINGS</span>
            <div className="flex flex-row gap-3 items-center">
              <FontAwesomeIcon icon={faClock} color="#00B902" />
              <span className="text-lato sm:text-sm md:text-xl">
                New Batch Starts
              </span>
              <span
                className="text-lato sm:text-sm md:text-xl"
                style={{ color: "#00B902" }}
              >
                Every Week!
              </span>
            </div>
            <div className="flex flex-col-reverse md:flex-row w-full">
              <div className="flex flex-row md:w-3/4 w-full">
                <div className="flex flex-col gap-2 mt-5 gap-3">
                  <span className="text-lato md:text-4xl">
                    {course.course_name}
                  </span>
                  <span className="text-lato md:text-lg">
                    {/* {"(grade 5 & 6)"} */}
                  </span>
                  <div className="grid grid-cols-2 gap-4 md:flex-row md:flex md:items-center md:flex-wrap md:gap-8 w-full">
                    <div className="flex gap-2 items-center">
                      <FontAwesomeIcon icon={faStar} color="gold" />
                      <span className="md:text-2xl text-sm text-lato text-nowrap">
                        4.5 (1645 ratings)
                      </span>
                    </div>
                    <div className="flex gap-2 items-center">
                      <FontAwesomeIcon icon={faUser} color="#FF40A5" />
                      <span className="md:text-2xl text-sm text-lato text-nowrap">
                        4k + Learners
                      </span>
                    </div>
                    <div className="flex gap-2 items-center">
                      <FontAwesomeIcon icon={faCalendar} color="#3970FF" />
                      <span className="md:text-2xl text-sm text-lato text-nowrap">
                        10 weeks
                      </span>
                    </div>
                    <div className="flex gap-2 items-center">
                      <FontAwesomeIcon icon={faClockFour} color="#FF9900" />
                      <span className="md:text-2xl text-sm text-lato text-nowrap">
                        {" "}
                        2 hours per class
                      </span>
                    </div>
                  </div>
                  <span className="text-lato text-medium mt-10">
                    {"Mentored By"}
                  </span>
                  <div className="flex flex-row items-center">
                    <Avatar
                      size={60}
                      src={<img src={profile} alt="avatar" />}
                    />
                    <div className="flex flex-col ml-4">
                      <span className="text-lato text-medium">
                        {course.mentor}
                      </span>
                      <span className="text-lato md:text-sm text-gray-500">
                        {"Knowvation Learning Pvt Ltd"}
                      </span>
                    </div>
                  </div>
                  <div className="grid justify-center grid-cols-2 md:flex md:flex-row md:items-center course-box gap-3 md:gap-12 w-full">
                    <div className="flex flex-row items-center">
                      <FontAwesomeIcon
                        icon={faSignal}
                        color="#00B902"
                        size={10}
                      />
                      <div className="flex flex-col ml-4 ">
                        <span className="text-lato md:text-xl text-sm">
                          {"Intermediate"}
                        </span>
                        <span className="text-lato text-xs md:text-sm text-gray-500">
                          {"Easy to understand"}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <FontAwesomeIcon
                        icon={faInfinity}
                        color="#00B902"
                        size={10}
                      />
                      <div className="flex flex-col ml-4 ">
                        <span className="text-lato md:text-xl text-sm">
                          {"Life time"}
                        </span>
                        <span className="text-lato text-xs md:text-sm text-gray-500">
                          {"Course Access"}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <FontAwesomeIcon
                        icon={faCertificate}
                        color="#00B902"
                        size={10}
                      />
                      <div className="flex flex-col ml-4 ">
                        <span className="text-lato md:text-xl text-sm">
                          {"Certificate"}
                        </span>
                        <span className="text-lato text-xs md:text-sm text-gray-500">
                          {"Lifetime Access"}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <FontAwesomeIcon
                        icon={faCreditCard}
                        color="#00B902"
                        size={10}
                      />
                      <div className="flex flex-col ml-4 ">
                        <span className="text-lato md:text-xl text-sm">
                          {"Affordable"}
                        </span>
                        <span className="text-lato text-xs md:text-sm text-gray-500">
                          {"Budget Friendly"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row md:w-3/12">
                <div className="flex flex-col justify-center gap-5">
                  <img src={coursetemp} className="w-full" alt="course image" />
                  <div className="flex flex-row items-center">
                    <span className="md:text-4xl text-lato">
                      <FontAwesomeIcon icon={faIndianRupee} />
                      {course && course.price}
                    </span>
                    <span
                      className="md:text-lg text-lato text-gray-500 ml-5"
                      style={{ textDecoration: "line-through" }}
                    >
                      <FontAwesomeIcon icon={faIndianRupee} />
                      {course && course.original_price}
                    </span>
                    {/* <span style={{ color: "#00B902" }}>10%off</span> */}
                  </div>
                  <div className="flex flex-row gap-3 items-center">
                    <span>
                      <span
                        className="text-poppins"
                        style={{ color: "#00B902" }}
                      >
                        <FontAwesomeIcon
                          icon={faClock}
                          color="#00B902"
                          className="mr-3"
                        />
                        Limited Time Offer{" "}
                      </span>
                    </span>
                  </div>
                  <div className="flex flex-row justify-center gap-5">
                    <Button
                      onClick={() => addToCart(course)}
                      className="text-lato"
                      style={{
                        width: "60%",
                        padding: 10,
                        borderColor: "black",
                        boxShadow: "6px 8px 0px 4px #7BF27C",
                      }}
                    >
                      <span>
                        <ShoppingCartOutlined className="md:text-lg mr-2" />
                        {cartbtnText}
                      </span>
                    </Button>
                    <Button
                      onClick={() => addToWishList(course)}
                      className="text-lato"
                      style={{
                        width: "10%",
                        padding: 10,
                        borderColor: "black",
                        boxShadow: "6px 8px 0px 4px #FF40A5",
                      }}
                    >
                      <FontAwesomeIcon icon={faHeart} color={`${iconColor}`} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-4" style={{ cursor: "pointer" }}>
            <a
              onClick={() => navigationBtn("about")}
              href="#about"
              className="text-poppins md:text-lg"
              style={{
                color: selected === "about" ? "black" : "grey",
                borderStyle: "none",
              }}
            >
              <span>About the course</span>
            </a>
            <a
              href="#content"
              className="text-poppins md:text-lg"
              style={{
                color: selected === "content" ? "black" : "grey",
                borderStyle: "none",
              }}
            >
              <span>Course Contents</span>
            </a>
            <a
              href="#rating"
              className="text-poppins md:text-lg"
              style={{
                color: selected === "rating" ? "black" : "grey",
                borderStyle: "none",
              }}
            >
              <span>Ratings & Reviews</span>
            </a>
          </div>
          <AboutCourse courseid={course.id} />
          <div id="content">
            {/* <span className="text-poppins mt-4">Course Contents</span>

            <CourseContent modulesData={courseModule} /> */}
          </div>
          {courseBatch &&
            courseBatch.map((batch) => (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-5">
                {batch.seats <= 15 ? (
                  <Badge.Ribbon
                    text={"few slots left"}
                    color="red"
                    placement="start"
                  >
                    <BlackCard
                      start={batch.start_date}
                      batchNo={batch.batch_no}
                    />
                  </Badge.Ribbon>
                ) : (
                  <BlackCard
                    start={batch.start_date}
                    batchNo={batch.batch_no}
                  />
                )}
              </div>
            ))}
          {/* <RatingAndReview /> */}
          {/* <span className="text-lato text-sm md:text-3xl flex flex-row md:justify-center mt-5">
            People also show interest on
          </span>
           <div className="grid grid-cols-1 md:grid-cols-3 mt-5 gap-5 whitespace-nowrap">
            <Card />
            <Card />
          </div> */}
        </>
      )}
    </div>
  );
}
