import React from "react";
import "./dashboardHeader.css";
import logo from "images/KnowvationIcon.jpeg";
import mobileLogo from "images/mobileIcon.jpeg";
import { Select, Space, Badge, Button, Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  faComment,
  faBell,
  faHeart,
} from "@fortawesome/free-regular-svg-icons";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import DropDown from "../dropdown/dropDown";
import DrawerFun from "../drawer/Drawer";
import { useCounter } from "../../pages/context/counterContext";

export default function HomeHeader() {
  const { cartCount, wishCount } = useCounter();
  const currentPage = "dashboard";
  const navigate = useNavigate();

  const setDropDownNavigation = (value) => {
    navigate(`/${value}`);
  };
  const navigateToLogin = () => {
    navigate("/login");
  };
  const [open, setOpen] = React.useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <header>
        <div className="flex flex-row justify-between items-center">
          <div className="basis-1/4 flex flex-row gap-10 items-center">
            <Link to="/">
              <img
                className="web-logo"
                src={"/logonew.png"}
                alt="logo knowvation"
              />
              <img
                className="mobile-logo"
                src={"/logolight.png"}
                alt="logo knowvation"
              />
            </Link>

            {currentPage !== "dashboard" && (
              <Button className="dashboard-header-btn">Dashboard</Button>
            )}
          </div>
          <div className="basis-1/2 flex flex-row justify-end gap-10 items-center">
            <div className="flex flex-row items-center gap-3">
              <Link to="/aboutus">
                <span className="text-poppins text-nowrap">About Us</span>
              </Link>
              <DropDown />
              <Link to="/contact">
                <span className="text-poppins text-nowrap">Contact Us</span>
              </Link>
              {/* <a
                className="hidden md:block"
                href="https://icube.knowvationlearnings.in/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-poppins">iCube</span>
              </a> */}
            </div>

            <div className="flex flex-row gap-6 items-center mr-1">
              <Button
                href="/login"
                className="text-lato hidden md:flex"
                style={{
                  borderColor: "black",
                  boxShadow: "6px 8px 0px 4px #FF40A5",
                }}
              >
                Login
              </Button>
              <Button
                href="/singup"
                className="text-lato hidden md:flex"
                style={{
                  borderColor: "black",
                  boxShadow: "6px 8px 0px 4px #7BF27C",
                }}
              >
                Apply as Tutor
              </Button>
              <FontAwesomeIcon
                onClick={showDrawer}
                className="icons menu"
                icon={faBars}
              />
            </div>
            <Badge count={`${cartCount}`} color="#7BF27C">
              <Link to="/cart">
                <ShoppingCartOutlined className="icons" />
              </Link>
            </Badge>
          </div>
          <DrawerFun setOpen={setOpen} open={open} />
        </div>
      </header>
    </>
  );
}
