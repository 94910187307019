import React from "react";
import profile from "../../images/profile.png";
export default function KeyFeatureCard({
  title,
  description,
  path,
  width,
  end,
}) {
  return (
    <>
      <div className="course-box flex flex-col justify-between">
        <span className="text-poppins md:text-xl text-lg">
          {title || "title"}
        </span>
        <span className="text-poppins md:text-sm text-xs">
          {description || "description"}
        </span>
        <div className={`flex w-full justify-${end || "center"} items-center`}>
          <img style={{ width: width }} src={path || profile} alt="image" />
        </div>
      </div>
    </>
  );
}
