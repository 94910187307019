import React from "react";
import { Button, Input } from "antd";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import whoweare from "images/whoweare.png";
import car1 from "images/car1.jpg";
import car2 from "images/car2.jpg";
import car3 from "images/car3.jpg";
import Footer from "components/footer/footer";
export default function About() {
  const videoRef = React.useRef(null);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const handlePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-2 md:gap-5 mt-10">
        <span className="text-poppins md:text-4xl text-lg justify-start items-center">
          WELCOME TO
        </span>
        <span
          style={{ color: "#7BF27C" }}
          className="text-pop md:text-7xl text-xl justify-start items-center"
        >
          KNOWVATION LEARNINGS
        </span>
        <span
          className="md:text-lg text-sm w-full md:w-3/6"
          style={{ textAlign: "center" }}
        >
          A platform built for students to learn the latest technologies,
          network and collaborate on projects, get mentors, thus transform their
          careers to get #Knowvated!
        </span>
        <div className="flex flex-col gap-5 mt-10">
          <div className="relative flex justify-center gap-10">
            <video
              src="https://upcdn.io/FW25c5x/raw/landing/Intro.mp4"
              className="w-full h-full md:h-1/4 rounded-lg"
              ref={videoRef}
              onEnded={() => setIsPlaying(false)}
            ></video>
            {!isPlaying && (
              <Button
                onClick={handlePlay}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-lato border-black shadow-[6px_8px_0px_4px_#7FDB80]"
              >
                <div className="px-1 bg-[#7FDB80] rounded-full">
                  <FontAwesomeIcon icon={faPlay} color="white" />
                </div>
                Who we are
              </Button>
            )}
          </div>
          <div className="grid grid-cols-2 md:flex md:flex-row justify-between md:gap-0 mt-10 gap-4">
            <span className="flex flex-col items-center md:text-black text-pink-500 text-poppins md:text-xl text-lg">
              25+
              <span className="md:text-2xl text-sm text-black">
                Regitstered Schools
              </span>
            </span>
            <div
              className="md:flex hidden"
              style={{ width: "4px", backgroundColor: "#3970FF" }}
            ></div>
            <span className="flex flex-col items-center md:text-black text-blue-500 font-bold text-poppins md:text-2xl text-lg">
              3000+
              <span className="md:text-2xl text-sm text-black">
                Enrolled Students
              </span>
            </span>
            <div
              className="hidden md:flex"
              style={{ width: "4px", backgroundColor: "#7BF27C" }}
            ></div>
            <span className="flex flex-col items-center md:text-black text-green-500 text-poppins md:text-2xl text-lg">
              20+
              <span className="md:text-2xl text-sm text-black">
                Regitstered Colleges
              </span>
            </span>
            <div
              className="hidden md:flex"
              style={{ width: "4px", backgroundColor: "#FF9040" }}
            ></div>

            <span className="flex flex-col items-center md:text-black text-orange-500 text-poppins md:text-2xl text-lg">
              10000+
              <span className="md:text-2xl text-sm text-black">
                Regitstered Students
              </span>
            </span>
          </div>
        </div>
        <div className="flex flex-col md:flex-row w-3/4 gap-5 mt-10">
          <div className="flex flex-col gap-5">
            <span className="text-pop md:text-3xl text-sm">Our motivation</span>
            <span className="text-pop md:text-lg text-xs  w-full">
              Knowvation Learnings began as a small group of friends who wanted
              the future generations to learn technologies in the right way and
              technically enable the country in all the sectors.{" "}
            </span>
            <span className="text-pop md:text-lg text-xs  w-full">
              We saw a gap in learning the technologies and practically
              implementing them and since then pledged to revolutionize it
              through project-based learning, giving hands-on experience and a
              platform that will be the backbone for all their tech needs and
              doubts.
            </span>
          </div>
          <img
            src={car1}
            className="md:w-3/6 w-full rounded-lg"
            alt="about us image"
          />
        </div>
        <div className="flex flex-col-reverse md:flex-row w-3/4 gap-5 mt-5">
          <img
            src={car2}
            className="md:w-3/6 w-full rounded-lg"
            alt="about us image"
          />
          <div className="flex flex-col gap-5">
            <span className="text-pop md:text-3xl text-sm">
              Where are we heading?
            </span>
            <span className="text-pop md:text-lg text-xs  w-full">
              We stay committed to our mission to impart technical knowledge
              with innovative learning modules, from a young age, which makes
              learning not only informative but interesting.{" "}
            </span>
            <span className="text-pop md:text-lg text-xs  w-full">
              We aim to contribute to the success story of every techy and wish
              to lay a route map for every student aspiring to make a career in
              this technically racing world.{" "}
            </span>
            <span className="text-pop md:text-lg text-xs  w-full">
              Our network of peers, mentors, trainers, entrepreneurs and
              employers will together contribute to the success of our mission
              for India as a country.{" "}
            </span>
          </div>
        </div>

        <div className="flex flex-col md:flex-row w-3/4 gap-5 mt-5">
          <div className="flex flex-col gap-5">
            <span className="text-pop md:text-3xl text-sm">
              Where do we see ourselves?
            </span>
            <span className="text-pop md:text-lg text-xs  w-full">
              Knowvation Learnings is what every student needs when it comes to
              learning technologies and we see ourselves revolutionizing the
              learning methodologies of students and us becoming the bridge for
              students to bring out the best in them.{" "}
            </span>
          </div>
          <img
            src={car3}
            className="md:w-3/6 w-full rounded-lg"
            alt="about us image"
          />
        </div>
        <div
          className="flex flex-col mb-20 w-full mt-5 background-img justify-center items-center gap-2 p-5 bg-black"
          style={{ borderRadius: "10px" }}
        >
          <span className="text-poppins md:text-3xl text-lg text-white flex flex-row md:justify-center">
            Ready to embark on the journey to the Future?
          </span>
          {/* <span
            style={{ color: "#FFFFFFB2", textAlign: "center" }}
            className="text-poppins md:text-sm text-xs flex flex-row w-full md:w-3/6 md:items-center text-wrap"
          >
            Lorem ipsum is a placeholder text commonly used in the design and
            typesetting industry. It consists of parts of Latin words with no
          </span> */}
          <div className="flex flex-row w-3/4 justify-center items-center gap-4">
            <Button
              // onClick={navigateToPageInfo}
              href="/#explore"
              className="text-lato md:w-3/12 w-3/4"
              style={{
                padding: 10,
                borderColor: "black",
                boxShadow: "4px 6px 0px 4px #7BF27C",
              }}
            >
              Explore
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
