import React from "react";
import { Button, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import hat from "../../images/hat.png";

export default function ExploreProgram({ title, subtitle, icon }) {
  let url = title;
  let hrefProps = {};
  if (title === "iCube") {
    url = "https://icube.knowvationlearnings.in";
    hrefProps = {
      target: "_blank",
      rel: "noreferer",
    };
  } else if (title === "Courses") url = "/Courses?category=combats";
  else if (title === "K.N.O.W") url = "/Services/description?id=d2808762-a506-4c19-a0ee-de96fb497696"
  return (
    <>
      <div
        className="flex flex-col card justify-between gap-10"
        style={{ width: "90%" }}
      >
        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-10">
            <div className="flex flex-row gap-3 items-center">
              <Avatar
                src={<img src={icon || hat} alt="card logo" />}
                size={30}
              />
              <span className="text-lato text-title gap-3">
                {title || "Courses"}
              </span>
            </div>
            <span className="text-poppins md:text-sm text-xs">
              {subtitle ||
                "By promoting creativity and encouraging the students to develop problem solving skills"}
            </span>
          </div>
        </div>

        <div className="flex flex-row items-center">
          <div className="flex justify-center" style={{ width: "100%" }}>
            <Button
              href={url}
              {...hrefProps}
              className="text-lato"
              style={{
                width: "50%",
                padding: 10,
                borderColor: "black",
                boxShadow: "6px 8px 0px 4px #FF40A5",
              }}
            >
              Explore
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
