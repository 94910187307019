import { Avatar } from "antd";
import React from "react";
import profile from "../../images/profile.png";

export default function TestiMonialCard({ title, subtitle, path, content }) {
  return (
    <>
      <div className="course-box flex flex-col p-2 gap-3 min-h-48">
        <div className="flex flex-row gap-3">
          <Avatar size={30} className="bg-green-300">
            {" "}
            {title.charAt(0)}{" "}
          </Avatar>
          <div className="flex flex-col">
            <span>{title || "title"}</span>
            <span>{subtitle || "sub title"}</span>
          </div>
        </div>
        <span className="text-pop md:text-xs text-xs text-wrap">
          {content || "content"}
        </span>
      </div>
    </>
  );
}
