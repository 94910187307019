import React from "react";
import HomeIntro from "./homeIntro";
import ExploreProgram from "./exploreProgram";
import career from "images/career.png";
import services from "images/services.png";
import hat from "images/hat.png";
import calendar from "images/calendar.png";
import cube from "images/cube.png";
import KeyFeatureCard from "./keyFeatureCard";
import iphone from "images/car5.jpg";
import team from "images/network.jpg";
import laptop from "images/innovative.jpg";
import mentor from "images/mentor.jpg";
import { Input, Button, message } from "antd";
import BlogsCard from "../blogs/blogsCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import TestiMonialCard from "../../components/cards/testiMonialCard";
import { getBlogs } from "../supa-base-client/blogs";
import Footer from "components/footer/footer";
import supabase from "pages/supa-base-client/supabase";
export default function Home() {
	const [messageApi, contextHolder] = message.useMessage();
	const errorMsg = () => {
		messageApi.open({
			type: "error",
			content: "Something Went Wrong!",
		});
	};
	const [blogsData, setBlogsData] = React.useState(null);
	const [isSubscribed, setSubscribed] = React.useState(
		localStorage.getItem("isSubscribed") || false
	);
	const [email, setEmail] = React.useState("");

	const subscribe = async () => {
		const { error } = await supabase
			.from("subscribers")
			.insert({ email: email });
		if (error !== null) {
			console.error(error);
			errorMsg();
		} else {
			localStorage.setItem("isSubscribed", true);
			setSubscribed(true);
		}
	};

	const [error, setError] = React.useState({
		error: false,
		message: "no error",
	});
	React.useEffect(() => {
		async function fetchBLogData() {
			try {
				const blogData = await getBlogs();
				setBlogsData(blogData);
			} catch (err) {
				console.error(err);
				setError({ error: true, message: err });
			}
		}
		fetchBLogData();
	}, []);
	return (
		<>
			<div className="flex flex-col gap-8">
				<HomeIntro />
				<div id="explore">
					<span className="text-poppins md:text-xl text-sm flex flex-row justify-center mt-5 mb-5">
						Explore our programs
					</span>

					<div className="flex md:flex-row flex-col gap-3 items-center mb-4 justify-center">
						<ExploreProgram
							title="Courses"
							icon={hat}
							subtitle="Learn the latest technologies from the best industry experts and gain hands-on experience to build real-time projects."
						/>
						<ExploreProgram
							title="Events"
							icon={calendar}
							subtitle="A place for students to build a network of tech enthusiasts and transform their careers."
						/>
						<ExploreProgram
							title="Services"
							icon={services}
							subtitle="Providing an exposure of the real-time corporate world with tailor made programs."
						/>
					</div>
					<div className="flex md:flex-row flex-col gap-3 items-center justify-center">
						<div className="flex md:flex-row flex-col w-2/3 gap-3">
							<ExploreProgram
								title="Careers"
								icon={career}
								subtitle="Team of techies working towards a mission to revolutionize education in India. Can you be one of us?"
							/>
							<ExploreProgram
								title="K.N.O.W"
								icon={cube}
								subtitle="Knowvation's National Olympiad of Webmasters"
							/>
							{/* <ExploreProgram
                title="iCube"
                icon={cube}
                subtitle="A platform built for students to learn, network and collaborate on projects, get mentors and to be hired."
              /> */}
						</div>
					</div>
				</div>

				<span className="text-poppins md:text-xl text-sm flex flex-row justify-center mt-5 mb-5">
					Key Features of Knowvation Learnings
				</span>
				<div className="flex justify-center">
					<div className="grid grid-cols-1 md:grid-cols-2 md:w-3/5 w-full gap-4">
						<KeyFeatureCard
							title="Project-based Learning"
							description="Students will get to learn the latest technologies completely based on projects, giving them a Chance for hands—on experience and effective learnings."
							path={iphone}
							width="100%"
						/>
						<KeyFeatureCard
							title="Network of Peers"
							description="The community of Knowvation Learnings is a right place for students to connect, discuss and build projects. They will get to co-learn and collaborate with students all across the nation."
							path={team}
							width="100%"
						/>
						<KeyFeatureCard
							title="Innovative Programs"
							description="We bring only the best for the students, All the programs and initiatives of Knowvation Learnings are completely focused on student's over all development and career building."
							path={laptop}
							width="100%"
							end="end"
						/>
						<KeyFeatureCard
							title="Mentorship and Exposure"
							description="Having a right mentor to guide is very crucial for a student. With the help of our hand holding mentorship programs, we help the students take informed career decisions."
							path={mentor}
							width="100%"
						/>
					</div>
				</div>
				<div
					className="flex flex-col mt-5 background-img justify-center items-center gap-2 p-5 bg-black"
					style={{ borderRadius: "10px" }}
				>
					<span className="text-poppins md:text-3xl text-lg text-white flex flex-row md:justify-center">
						The Latest News, Events and Stories delivered right to
						your inbox!
					</span>
					<span
						style={{ color: "#FFFFFFB2", textAlign: "center" }}
						className="text-poppins md:text-sm text-xs flex flex-row w-full md:w-3/6 md:items-center text-wrap"
					>
						Stay Ahead of the Curve—Sign Up for Our Newsletter and
						Get the Latest Insights, Updates, and Opportunities
						Delivered Straight to Your Inbox!
					</span>
					{!isSubscribed && (
						<>
							<div className="flex flex-row w-2/3 justify-center items-center gap-4">
								<Input
									className="min-w-24"
									onChange={(value) =>
										setEmail(value.target.value)
									}
									value={email}
									placeholder="Email"
								/>
								<Button
									onClick={subscribe}
									className="text-lato md:w-3/12 w-3/4"
									style={{
										padding: 10,
										borderColor: "black",
										boxShadow: "4px 6px 0px 4px #7BF27C",
									}}
								>
									Subscribe
								</Button>
							</div>
						</>
					)}
					{isSubscribed && (
						<>
							<div className="flex flex-row justify-center">
								<span className="text-white text-center text-poppins md:text-sm text-xs flex flex-row w-full md:items-center text-wrap">
									Thank you for Subscribing to our Newsletter!
								</span>
							</div>
						</>
					)}
				</div>
				<div className="flex justify-center gap-4">
					<div className="flex flex-col justify-center items-center mt-5 w-full md:w-3/4">
						<span className="flex flex-row justify-center items-center text-poppins md:text-xl text-sm">
							Our Recent Blogs
						</span>
						<span
							className="flex flex-row flex-wrap w-full md:w-3/6 items-center text-poppins md:text-sm text-xs"
							style={{
								textAlign: "center",
								alignItems: "center",
							}}
						>
							Empower Your Mind—Discover Our Blog for the Latest
							Trends, Thought-Provoking Insights, and Expert
							Analysis on Technology and Learning.
						</span>
						<div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-4">
							{blogsData &&
								blogsData.map((blog) => (
									<BlogsCard blog={blog} />
								))}
						</div>
						<div className="flex flex-row md:w-3/12 mt-4">
							<Button
								href="/blogs"
								className="text-lato"
								style={{
									width: "100%",
									padding: 10,
									borderColor: "black",
									boxShadow: "4px 6px 0px 4px #7BF27C",
								}}
							>
								Read our blogs
								<FontAwesomeIcon icon={faArrowRight} />
							</Button>
						</div>
					</div>
				</div>
				<div className="flex justify-center">
					<div className="flex flex-col justify-center items-center mt-5 w-full md:w-3/4 gap-6">
						<span className="flex flex-row justify-center items-center text-poppins md:text-xl text-sm">
							Testimonials
						</span>
						<div className="grid grid-cols-1 items-center md:grid-cols-3 gap-3">
							<TestiMonialCard
								title="T. Shivani"
								subtitle="BSc, Loyola Academy, Hyderabad"
								content="The lecture was very clear to understand and quite interesting. Appreciate the lecturer's way of interaction with students and gained a lots of knowledge in perspective of technology and business"
							/>
							<TestiMonialCard
								title="P. Anusha"
								subtitle="B.TECH(CSE), CMREC, Hyderabad"
								content="Learnt about how companies are recruiting the freshers like what skills are based on. Learnt about technologies and public speaking"
							/>
							<TestiMonialCard
								title="Kushwanth Reddy"
								subtitle="B.TECH(CSE), MLRIT, Hyderabad"
								content="The course on AI/ML was very informative. It definitely gave me the opportunity to build projects like hand gesture recognition and many more. Understood a lot of things that are needed in order to have a great career. We got to hear the trainer's experience on how to get into good companies."
							/>
						</div>
						{/* <Button
              // onClick={navigateToPageInfo}
              className="text-lato md:w-3/12 w-full"
              style={{
                padding: 10,
                borderColor: "black",
                boxShadow: "4px 6px 0px 4px #7BF27C",
              }}
            >
              See more <FontAwesomeIcon icon={faArrowRight} />
            </Button> */}
					</div>
				</div>
			</div>
		</>
	);
}
