import React from "react";
import coursetemp from "../../../images/coursetemp.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTag,
  faIndianRupeeSign,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { HeartOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Button } from "antd";
export default function WishListCard({ course }) {
  const removeFromCart = (id) => {
    const cart_list = JSON.parse(localStorage.getItem("user_cart_courses"));
    const updatedCourses = cart_list.filter((course) => course.id !== id);
    localStorage.setItem("user_cart_courses", JSON.stringify(updatedCourses));
  };

  const addToWishList = (course) => {
    let wish_courses = localStorage.getItem("user_wishlist_courses");
    let courses = wish_courses != null ? JSON.parse(wish_courses) : [];
    const isThere = courses && courses.find((co) => co.id === course.id);
    if (!isThere) courses.push(course);
    localStorage.setItem("user_wishlist_courses", JSON.stringify(courses));
  };
  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <div className="flex items-center" style={{ width: "100%" }}>
          <div className="flex flex-row  gap-2 justify-center mt-4">
            <div className="flex flex-col w-3/6 h-3/6 justify-center items-center">
              <img className="w-full" src={coursetemp} alt="course_image" />
              <div
                className="flex flex-row justify-center gap-5 mt-5 mb-3"
                style={{ width: "100%" }}
              >
                <Button
                  onClick={() => removeFromCart(course.id)}
                  className="text-lato md:text-lg text-xs w-auto"
                  style={{
                    padding: 10,
                    borderColor: "black",
                    boxShadow: "6px 6px 0px 4px #FF0000",
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                  Remove
                </Button>
                <Button
                  onClick={() => addToWishList(course)}
                  className="w-auto text-xs md:text-lg"
                  style={{
                    padding: 10,
                    borderColor: "black",
                    boxShadow: "6px 6px 0px 4px #3970FF",
                  }}
                >
                  <HeartOutlined />
                  <span className="text-lato md:text-lg">Wishlist</span>
                </Button>
              </div>
            </div>
            <div className="flex flex-col gap-2 md:gap-5 md:mt-5 mt-0">
              <span className="text-lato text-sm md:text-3xl">
                {course.course_name}
              </span>
              <span className="text-lato text-xs md:text-2xl">
                {`grade ${course.grade}`}
              </span>
              <div className="flex flex-col">
                <span className="text-poppins text-sm text-gray-500 md:text-2xl">
                  {"Mentor By"}
                </span>
                <span className="text-lato text-lg md:text-xl">
                  {course.mentor}
                </span>
              </div>
              <div className="flex flex-row gap-1 md:gap-3">
                <span
                  className="text-lato text-nowrap md:text-2xl text-lg"
                  style={{ color: "#00B902" }}
                >
                  <FontAwesomeIcon icon={faTag} />
                  <FontAwesomeIcon icon={faIndianRupeeSign} />
                  {course.price}
                </span>
                <span
                  className="text-lato text-nowrap md:text-2xl text-xs"
                  style={{ textDecoration: "line-through", color: "gray" }}
                >
                  <FontAwesomeIcon icon={faIndianRupeeSign} />
                  {course.original_price}
                </span>
                {/* <span
                  className="text-lato md:text-lg text-nowrap text-xs"
                  style={{ color: "#FF9900" }}
                >
                  10% off
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
