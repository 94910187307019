import React, { useEffect, useInsertionEffect, useState } from "react";
import "./dashboard.css";
import hellogif from "../../gifs/handgif.gif";
import { Button, Select } from "antd";
import All from "./pages/all";
import Courses from "./pages/courses";
import Events from "./pages/events";
import ProjectGuidance from "./pages/projectGuidance";
import Intellexus from "./pages/intellexus";
import { useAuth } from "pages/auth/authProvider";
import { getUserRegisteredCourses } from "pages/supa-base-client/user";
import { getCourses } from "pages/supa-base-client/courses";

export default function StudentDashboard() {
  const [selected, setSelected] = React.useState("all");
  const { user } = useAuth();
  const [courses, setCourses] = useState(null);

  const navigationBtn = (screen_name) => {
    setSelected(screen_name);
  };

  return (
    <>
      <div className="flex flex-col gap-6 mt-5">
        <div className="flex justify-center">
          <div className="welcome-msg flex flex-row">
            <img
              style={{ width: "50px", height: "100%" }}
              src={hellogif}
              alt="hello gif"
            />
            <span className="text-lato text-sm">Hey, {user && user.name}</span>
          </div>
        </div>
        <div className="flex justify-center">
          <div
            className="flex flex-row navigation-buttons justify-between"
            style={{ width: "95%" }}
          >
            <Button
              onClick={() => navigationBtn("all")}
              className="btn"
              style={{
                backgroundColor: selected === "all" ? "#7BF27C" : "white",
              }}
            >
              <span className="text-poppins btn-text">All</span>
            </Button>

            <Button
              onClick={() => navigationBtn("courses")}
              className="btn"
              style={{
                backgroundColor: selected === "courses" ? "#7BF27C" : "white",
              }}
            >
              <span className="text-poppins btn-text">Courses</span>
            </Button>
            <Button
              onClick={() => navigationBtn("events")}
              className="btn"
              style={{
                backgroundColor: selected === "events" ? "#7BF27C" : "white",
              }}
            >
              <span className="text-poppins btn-text">Events</span>
            </Button>
            <Button
              onClick={() => navigationBtn("mocktests")}
              className="btn"
              style={{
                backgroundColor: selected === "mocktests" ? "#7BF27C" : "white",
              }}
            >
              <span className="text-poppins btn-text">KNOW Mock Tests</span>
            </Button>
            {/* <Button
              onClick={() => navigationBtn("intellexus")}
              className="btn"
              style={{
                backgroundColor:
                  selected === "Intellexus" ? "#7BF27C" : "white",
              }}
            >
              <span className="text-poppins btn-text">Intellexus</span>
            </Button> */}
          </div>
          <div className="navigation-dropdown" style={{ width: "95%" }}>
            <Select
              defaultValue="All"
              style={{ width: "100%" }}
              onChange={(value) => navigationBtn(value)}
              options={[
                { value: "all", label: "All" },
                { value: "courses", label: "Courses" },
                { value: "events", label: "Events" },
                // { value: "projectGuidance", label: "Project Guidance" },
                // { value: "intellexus", label: "Intellexus" },
              ]}
            />
          </div>
        </div>
        <div className="flex md:m-10 m-2">
          {selected === "all" && <Courses />}
          {selected === "courses" && <Courses />}
          {selected === "events" && <Events />}
          {selected === "mocktests" && ""}
          {/* {selected === "intellexus" && <Intellexus />} */}
        </div>
      </div>
    </>
  );
}
