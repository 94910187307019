import React from "react";
import { Avatar, Input } from "antd";
import "./chat.css";
import profile from "../../images/profile.png";
import { SearchOutlined } from "@ant-design/icons";
export default function ChatDashBoard() {
  return (
    <>
      <div
        className="w-full flex flex-col gap-5 overflow-y-scroll"
        style={{ borderWidth: "0px 2px 0px 0px" }}
      >
        <Input
          size="large"
          className="custom-placeholder"
          placeholder="Search by mentor or name of course"
          prefix={<SearchOutlined />}
        />
        <div
          className="flex flex-row gap-5"
          style={{ borderWidth: "2px 0px 2px 0px", padding: "5px 0px 5px 0px" }}
        >
          <Avatar size={50} icon={<img src={profile} alt="mentor avatar" />} />
          <div className="flex flex-col">
            <span className="text-lato">Varsha</span>
            <span>Course-Full stack developement</span>
          </div>
        </div>
      </div>
    </>
  );
}
