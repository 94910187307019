import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { Link } from "react-router-dom";
const items = [
  {
    label: (
      <Link to="/Courses?category=combats">
        <span className="pr-10">Courses</span>
      </Link>
    ),
    key: "0",
  },
  {
    type: "divider",
  },
  {
    label: (
      <Link to="/Services">
        <span>Services</span>
      </Link>
    ),
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: (
      <Link to="/Blogs">
        <span>Blogs</span>
      </Link>
    ),
    key: "2",
  },
  {
    type: "divider",
  },
  {
    label: (
      <Link to="/Events">
        <span>Events</span>
      </Link>
    ),
    key: "3",
  },
  {
    type: "divider",
  },
  {
    label: (
      <Link to="/Careers">
        <span>Careers</span>
      </Link>
    ),
    key: "4",
  },

  {
    type: "divider",
  },
  // {
  //   label: (
  //     <Link to="/Olympiads">
  //       <span>Olympiad Mock Tests</span>
  //     </Link>
  //   ),
  //   key: "5",
  // },
];
const DropDown = () => (
  <Dropdown
    className="text-pop text-black"
    menu={{
      items,
    }}
  >
    <div onClick={(e) => e.preventDefault()}>
      <Space>
        Explore
        <DownOutlined />
      </Space>
    </div>
  </Dropdown>
);
export default DropDown;
