import React from "react";
import Footer from "components/footer/footer";

export default function RefundPolicy() {
  return (
    <>
      <div className="md:m-10 mt-10 mb-10">
        <p className="demoTitle">&nbsp;</p>
        <h1>Knowvation Learnings Refund Policy</h1>
        <p>Effective Date: 18/08/2023</p>
        <p className="demoTitle">&nbsp;</p>
        <h3>1. Refund Eligibility:</h3>
        <p className="demoTitle">&nbsp;</p>
        <p>
          1.1 Knowvation Learnings&nbsp;("the Company")&nbsp;offers refunds
          under certain circumstances for courses and services purchased
          directly through our platform.
        </p>
        <p>1.2 Refunds are generally considered in the following scenarios:</p>
        <ul>
          <li>Course cancellation by the Company.</li>
          <li>
            Inability to access the course due to technical issues caused by the
            Company.
          </li>
          <li>
            Dissatisfaction with the course content or quality, subject to
            specific conditions outlined in this policy.
          </li>
        </ul>
        <p className="demoTitle">&nbsp;</p>
        <h3>2. Refund Request Process:</h3>
        <p className="demoTitle">&nbsp;</p>
        <p>
          2.1 Customers must submit a refund request within 7 days of the
          purchase date or course start date.
        </p>
        <p>
          2.2 Refund requests should be sent to
          <a href="mailto:support@knowvationlearnings.in">
            support@knowvationlearnings.in
          </a>
        </p>
        <p className="demoTitle">, providing the following details:</p>
        <ul>
          <li>Name of the course or service</li>
          <li>Reason for the refund request</li>
          <li>Proof of purchase&nbsp;(e.g., order confirmation, receipt)</li>
        </ul>
        <p className="demoTitle">&nbsp;</p>
        <h3>3. Refund Approval and Processing:</h3>
        <p className="demoTitle">&nbsp;</p>
        <p>
          3.1 The Company will review refund requests on a case-by-case basis
          and reserves the right to approve or deny requests at its discretion.
        </p>
        <p>
          3.2 Approved refunds will be processed within 15 days of the refund
          request approval date.
        </p>
        <p>
          3.3 Refunds will be issued using the original payment method used for
          the purchase, unless otherwise specified by the customer.
        </p>
        <h3>4. Non-Refundable Items and Services:</h3>
        <p className="demoTitle">&nbsp;</p>
        <p>
          4.1 Certain items and services are non-refundable, including but not
          limited to:
        </p>
        <ul>
          <li>Mentorship fees for iCube community access</li>
          <li>Registration fees for competitions</li>
          <li>
            Any services rendered or products consumed beyond the refund request
            date
          </li>
        </ul>
        <p className="demoTitle">&nbsp;</p>
        <h3>5. Course-Specific Refund Policies:</h3>
        <p className="demoTitle">&nbsp;</p>
        <p>
          5.1 Some courses may have specific refund policies determined by the
          course instructor or outlined at the time of purchase. Customers are
          encouraged to review these policies before enrollment.
        </p>
        <p className="demoTitle">&nbsp;</p>
        <h3>6. Contact Information:</h3>
        <p className="demoTitle">&nbsp;</p>
        <p>
          6.1 For questions or assistance regarding refunds, customers can
          contact our support team at
          <a href="mailto:support@knowvationlearnings.in">
            support@knowvationlearnings.in
          </a>
          .
        </p>
        <p className="demoTitle">&nbsp;</p>
        <h3>7. Policy Updates:</h3>
        <p className="demoTitle">&nbsp;</p>
        <p>
          7.1 The Company reserves the right to modify or update this refund
          policy at any time. Any changes will be communicated to customers via
          email or through our website.
        </p>
        <p className="demoTitle">&nbsp;</p>
        <h3>8. Governing Law:</h3>
        <p className="demoTitle">&nbsp;</p>
        <p>
          8.1 This refund policy shall be governed by and construed in
          accordance with the laws of consumer, without regard to its conflict
          of law principles.
        </p>
      </div>
    </>
  );
}
