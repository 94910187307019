import React, { useState } from "react";
import CartCard from "./cartcard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag, faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { Button, Input, Form, Select, notification } from "antd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getSelectedCourse } from "pages/supa-base-client/courses";
import { useCounter } from "pages/context/counterContext";
import { useForm } from "antd/es/form/Form";
import { cashfree } from "util/cashfree";
import {
  getCouponValidity,
  getCourseBatch,
  getNotification,
  getPaymentResponse,
} from "util/methods";
import { useAuth } from "pages/auth/authProvider";
export default function PaymentPreview() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [form] = Form.useForm();
  let { course: courseProp } = location.state || {};
  const [course, setCourse] = React.useState(courseProp || {});
  const { user } = useAuth();
  const [courseBatches, setBatches] = useState([]);
  const { cartCount } = useCounter();
  React.useEffect(() => {
    const fetchCourse = async () => {
      if (!course || Object.keys(course).length === 0) {
        const result = await getSelectedCourse(id);
        setCourse(result[0]);
      }
      const res = await getCourseBatch();
      setBatches(res);
    };

    if (currentBatch !== "20241113")
      setSlots([
        { value: "10", label: "10AM to 3PM" },
        { value: "12", label: "12AM to 05PM" },
        { value: "03", label: "03PM to 08PM" },
        { value: "04", label: "04PM to 09PM" },
      ]);
    else
      setSlots([
        { value: "07", label: "07PM to 09PM" },
        { value: "08", label: "08PM to 10PM" },
      ]);

    fetchCourse();
  }, [id]);

  const navigate = useNavigate();
  const [status, setStatus] = React.useState(true);
  const [currentBatch, setBatch] = React.useState("");

  const [slots, setSlots] = React.useState([]);

  const batches = courseBatches.map((batch) => ({
    value: batch.start_date.split("-").join(""),
    label: batch.start_date,
  }));
  const [api, contextHolder] = notification.useNotification();

  const applyCoupon = async () => {
    const values = form.getFieldsValue();
    const { coupon } = values;
    const res = await getCouponValidity(coupon);
    if (!res || !res?.valid) {
      getNotification(
        api,
        "error",
        "Coupon invalid",
        "please enter the valid coupoun code furthur queries please contact the coupon provider"
      );
      return;
    }
    getNotification(
      api,
      "success",
      "coupon valid",
      "please proceed to checkout to activate the coupon discount"
    );
  };

  const pay = async () => {
    const values = form.getFieldsValue();
    localStorage.setItem("checkout_course", id);
    const { coupon, batch, slot, email, phone } = values;

    const relatedTo = "course";
    const relatedToId = id;
    const formData = {
      coupon: coupon,
      email: email,
      phone: phone,
      relatedTo: relatedTo,
      relatedToId: relatedToId,
      batch,
      slot,
    };

    if (!formData.batch || !formData.email || !formData.slot) {
      getNotification(
        api,
        "error",
        "please enter the required fields",
        "enter all the fileds - email,batch,slot coupon if you have one"
      );
      return;
    }
    const url = await getPaymentResponse(formData);
    console.log(url, "session id");
    let checkoutOptions = {
      paymentSessionId: url,
      redirectTarget: "_blank",
    };
    if (url === null || url === undefined) {
      getNotification(api, "error");
    }
    cashfree.checkout(checkoutOptions);
    // console.log(url, "urlf");
    // window.location.href = url;
  };

  return (
    <>
      {contextHolder}
      <div className="flex flex-col justify-center md:flex-row mt-10">
        <div className="flex flex-col md:w-3/6 w-full">
          {/* <span className="text-poppins md:text-2xl text-sm mb-5 mt-5">
            {cartCount || 1} Course in cart
          </span> */}
          <CartCard course={course} />
          {/* <span className="text-poppins md:text-2xl text-sm mb-5 mt-5 md:flex hidden">
            1 Course in Wishlist
          </span> */}
          {/* <div className="md:flex hidden">
            <WishListCard />
          </div> */}
        </div>
        <div className="flex flex-col md:w-3/6 w-full justify-center">
          <span className="text-poppins md:text-xl text-sm">Total:</span>
          <div className="flex flex-row gap-3 mb-5">
            <span
              className="text-lato md:text-2xl text-lg"
              style={{ color: "#00B902" }}
            >
              <FontAwesomeIcon icon={faTag} />
              <FontAwesomeIcon icon={faIndianRupeeSign} />
              {course && course.price}
            </span>
            <span
              className="text-lato md:text-2xl text-lg"
              style={{
                textDecoration: "line-through",
                color: "gray",
              }}
            >
              <FontAwesomeIcon icon={faIndianRupeeSign} />
              {course && course.original_price}
            </span>
            {/* <span
              className="text-lato md:text-lg text-sm"
              style={{ color: "#00B902" }}
            >
              10% off
            </span> */}
          </div>

          <Form form={form} layout="vertical">
            <Form.Item className="text-lato" name="coupon">
              <div className="flex flex-row gap-4">
                <Input
                  style={{ height: "50px" }}
                  placeholder="Enter Coupon Code"
                />
                <Button
                  onClick={() => applyCoupon()}
                  style={{
                    boxShadow: "6px 6px 0px 3px #00B902",
                  }}
                  className="text-lato"
                >
                  Apply
                </Button>
              </div>
            </Form.Item>
            <Form.Item className="text-lato" name="email">
              <Input
                style={{ height: "50px" }}
                placeholder="Enter your Email"
                required
              />
            </Form.Item>
            <Form.Item className="text-lato" name="phone">
              <Input
                style={{ height: "50px" }}
                placeholder="Enter your Phone Number"
                required
              />
            </Form.Item>
            <Form.Item className="text-lato" name="batch">
              <Select
                defaultValue="Select Batch"
                style={{ width: "100%", height: "50px" }}
                options={batches}
                onChange={(value) => setBatch(value)}
              />
            </Form.Item>
            <Form.Item className="text-lato" name="slot">
              <Select
                defaultValue="Select Slot"
                style={{ width: "100%", height: "50px" }}
                options={slots}
              />
            </Form.Item>

            <Form.Item>
              <div className="flex justify-center">
                <Button
                  onClick={() => pay()}
                  className="text-lato"
                  style={{
                    width: "80%",
                    padding: 10,
                    borderColor: "black",
                    boxShadow: "10px 10px 0px 4px #7BF27C",
                  }}
                >
                  Checkout
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}
