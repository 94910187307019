import React, { useState, useEffect } from "react";
import "./auth.css";
import { Form, Input, Button, Checkbox, Divider, Alert } from "antd";
import {
  GoogleSquareFilled,
  FacebookFilled,
  GithubFilled,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./authProvider";
import ShadowButton from "components/buttons/shadowButton";
export default function Login() {
  const navigate = useNavigate();
  const { loginOAuth, login, isAuthenticated } = useAuth();
  const [OAuthError, setOAuthError] = useState(false);
  const [authError, setAuthError] = useState(false);

  const [form] = Form.useForm();
  const navigateToDashboard = () => {
    navigate("/dashboard");
  };

  const onLogin = async () => {
    const email = form.getFieldValue("email");
    const password = form.getFieldValue("password");
    const check = await login(email, password);
    if (check) navigateToDashboard();
    else setAuthError(true);
  };

  useEffect(() => {
    if (isAuthenticated) navigate("/dashboard");
  }, [isAuthenticated]);

  return (
    <>
      <div
        className="flex flex-col justify-center items-center login"
        style={{ height: "100vh" }}
      >
        {OAuthError && (
          <Alert
            message="Something went wrong"
            description="Please try again after some time!"
            type="error"
            showIcon
          />
        )}
        {authError && (
          <Alert
            message="Invalid Username or Password!"
            description="Please enter valid credentials!"
            type="error"
            showIcon
          />
        )}
        <div className="flex flex-col justify-center items-center mt-4 md:w-1/2 w-full">
          <span className="text-lato md:text-3xl text-xl">
            Welcome Back,
            <span className="text-lato" style={{ color: "#FF40A5" }}>
              Innovator
            </span>
          </span>
          <span className="text-lato mt-2 md:text-sm text-xs">
            Log In and Resume Your Coding Adventures
          </span>
          <div className="flex flex-col w-full md:w-3/6 mt-8">
            <Form
              form={form}
              layout="vertical"
              labelCol={{
                xs: {
                  span: 24,
                },
                sm: {
                  span: 6,
                },
              }}
              wrapperCol={{
                xs: {
                  span: 24,
                },
                sm: {
                  span: 24,
                },
              }}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email!",
                  },
                ]}
                className="text-lato"
              >
                <Input
                  type="email"
                  style={{ borderColor: "black" }}
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter password!",
                  },
                ]}
                className="text-lato"
              >
                <Input.Password
                  style={{ borderColor: "black" }}
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item name="remember">
                <div className="flex flex-row justify-between">
                  <Checkbox>Remember me</Checkbox>
                  <a href="/forgot">Forgot Password?</a>
                </div>
              </Form.Item>
              <Form.Item>
                <div className="flex justify-center">
                  <ShadowButton onclick={onLogin} />
                </div>
              </Form.Item>
            </Form>
            <span className="w-full text-center" style={{ color: "grey" }}>
              Don't have an account ?
              <Link to="/singup">
                {" "}
                <span style={{ color: "black" }}>Signup</span>
              </Link>
            </span>
            <Divider plain>
              <span className="text-lato">or</span>
            </Divider>
            <div className="flex flex-row justify-center gap-10">
              <button type="button" onClick={() => loginOAuth("google")}>
                <GoogleSquareFilled style={{ fontSize: "40px" }} />
              </button>
              {/* <button type="button" onClick={() => loginOAuth("facebook")}>
                <FacebookFilled style={{ fontSize: "40px" }} />
              </button> */}
              <button type="button" onClick={() => loginOAuth("github")}>
                <GithubFilled style={{ fontSize: "40px" }} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
