import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Markdown from "react-markdown";
import {
	faDotCircle,
	faLocationPin,
	faStar,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";
import { Button } from "antd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getServiceProject } from "../supa-base-client/services";
import Forms from "components/forms/form";

export default function ServiceDescription() {
	const location = useLocation();
	const navigate = useNavigate();
	const [error, setError] = useState({ error: false, message: "no error" });
	let { pro: projectInfo } = location.state || {};
	const [serv, setPro] = React.useState(projectInfo || {});
	const [alsoL, setAlso] = React.useState([]);
	const [type, setType] = React.useState("others");
	const [formId, setFormId] = React.useState(null);
	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");
	React.useEffect(() => {
		const fetchCourse = async () => {
			try {
				const temp = await getServiceProject();
				const also = temp.filter((x) => x.id !== id);
				if (!serv || Object.keys(serv).length === 0) {
					const result = temp.filter((x) => x.id === id);
					const tempType = result[0].type;
					if (tempType === "olympiad") setType(tempType);
					setPro(result[0]);
				}
				else{
					const tempType = serv.type;
					if (tempType === "olympiad") setType(tempType);
				}
				setAlso(also);
			} catch (err) {
				setError({ error: true, message: err });
			}
		};

		fetchCourse();
	}, [id]);
	const navigateToCareerApply = (id) => {
		navigate(`/Contact?slug=${serv.name}`);
	};

	return (
		<>
			<div className="flex flex-col md:flex-row w-full gap-10 mt-5">
				<div className="flex flex-col gap-3 md:w-3/5 w-full">
					<div className="course-box background-img flex gap-4 flex-col">
						<span
							className="md:text-xl text-sm text-poppins text-nowrap"
							style={{ color: "#00B902" }}
						>
							Knowvation Learnings Pvt Ltd
						</span>
						<div className="flex flex-col gap-2">
							<span className="text-lato text-title text-nowrap">
								{serv.name}
							</span>
							{/* <span className="text-lato text-sub-title">{"Mentorship"}</span> */}
						</div>
						<div className="flex mb-5 justify-around flex-row items-center gap-5">
							{/* <div className="flex flex-row items-center gap-2 text-nowrap">
                <FontAwesomeIcon icon={faLocationPin} />
                <span className="text-poppins md:text-lg text-sm text-gray-500">
                  {"Hyderabad"}
                </span>
              </div> */}
							{/* <div className="flex flex-row items-center gap-2 text-nowrap">
                <FontAwesomeIcon icon={faCalendar} />
                <span className="text-poppins md:text-lg text-sm text-gray-500">
                  {"1 month"}
                </span>
              </div> */}
			  	{type !== "olympiad" && <Button
								onClick={() => navigateToCareerApply()}
								className="text-lato"
								style={{
									padding: 10,
									borderColor: "black",
									boxShadow: "6px 8px 0px 4px #00B902",
									width: "30%",
									height: "100%",
								}}
							>
								Contact Us
							</Button>}
							{type === "olympiad" && <>
								<Button
								onClick={() => setFormId(serv.school_form_id)}
								className="text-lato"
								style={{
									padding: 10,
									borderColor: "black",
									boxShadow: "6px 8px 0px 4px #00B902",
									width: "30%",
									height: "100%",
								}}
							>
								Register as a School
							</Button>
								<Button
								onClick={() => setFormId(serv.form_id)}
								className="text-lato"
								style={{
									padding: 10,
									borderColor: "black",
									boxShadow: "6px 8px 0px 4px #ff40a5",
									width: "30%",
									height: "100%",
								}}
							>
								Register as a Student
							</Button>
							</>}
							
							
						</div>

						{/* <div className="flex flex-row justify-end gap-10 pr-4 pb-3">
              <span className="text-gray-500 text-poppins md:text-xl text-sm">
                No of students got mentorship :{" "}
                <span className="text-poppins text-black">{serv.count}</span>
              </span>
              
            </div> */}
					</div>
					<div className="course-box flex flex-col">
						<span className="text-poppins md:text-xl text-lg"></span>
						<span className="text-poppins md:text-sm text-xs">
							<Markdown>{serv.about}</Markdown>
						</span>
					</div>
					{/* <div className="course-box flex flex-col">
            <span className="text-poppins md:text-xl text-lg">
              Responsbilities
            </span>
            <span className="text-poppins md:text-sm text-xs">
              {serv.responsibilities}
              <Markdown>{serv.responsibilities}</Markdown>
            </span>
          </div> */}
				</div>
				<div className="flex flex-col gap-3 w-full md:w-2/5">
					<span className="text-lato text-black md:text-lg text-sm">
						{formId === null ? "Knowvation Learnings also offers" : "Enter Your Details"}
					</span>
					{alsoL &&
						formId === null &&
						alsoL.map((x) => {
							return (
								<div className="course-box flex flex-col gap-3">
									<span className="text-poppins md:text-lg text-sm text-nowrap">
										{x.name}
									</span>
									<div className="flex flex-row items-center gap-5">
										<div className="flex flex-row items-center gap-2 text-nowrap">
											<FontAwesomeIcon icon={faUser} />
											<span className="text-poppins md:text-lg text-sm text-gray-500">
												{x.level}
											</span>
										</div>
										<div className="flex flex-row items-center gap-2 text-nowrap">
											<FontAwesomeIcon icon={faClock} />
											<span className="text-poppins md:text-lg text-sm text-gray-500">
												{x.duration}
											</span>
										</div>
									</div>
								</div>
							);
						})}
						{formId !== null && 
						<div className="course-box flex flex-col gap-3">
							<Forms id={formId} />
						</div>}
				</div>
			</div>
		</>
	);
}
