import supabase from "./supabase";

export const getBlogs = async () => {
  try {
    const { data, error } = await supabase.from("blogs").select("*");
    if (error) {
      console.error(error);
      return;
    }
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getRecentBlogs = async (id) => {
  try {
    const now = new Date();
    const cutoffDate = new Date(now.getTime() - 48 * 60 * 60 * 1000);
    const cutoffDateISO = cutoffDate.toISOString();
    const { data, error } = await supabase
      .from("blogs")
      .select("*")
      //.gte("created_at", cutoffDateISO);
      .neq("id",id)
    if (error) {
      console.error(error);
      return;
    }
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getSelectedBlog = async (id) => {
  try {
    console.log(id);
    const { data, error } = await supabase
      .from("blogs")
      .select("*")
      .eq("id", `${id}`);
    if (error) {
      console.error(error);
      return;
    }
    console.log(data);
    return data;
  } catch (err) {
    console.error(err);
  }
};
