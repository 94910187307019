import React from "react";
import DashBoardHeader from "../../components/header/dashboardHeader";
import Card from "./card";
import HomeHeader from "../../components/header/homeHeader";
export default function Mentor() {
  return (
    <>
      <div>
        <div className="grid grid-cols-1 md:grid-cols-3 mt-10">
          <Card type="courses" />
        </div>
      </div>
    </>
  );
}
