import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";

import car6 from "images/car6.jpg";
import { faBuilding, faClock } from "@fortawesome/free-regular-svg-icons";

import { Button } from "antd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getSelectedEvent } from "../supa-base-client/events";
import HomeHeader from "../../components/header/homeHeader";
export default function EventDescription() {
  const navigate = useNavigate();
  const location = useLocation();
  let { eventinfo: event } = location.state || {};
  const [eventinfo, setEventInfo] = React.useState(event || {});
  const [error, setError] = useState({ error: false, message: "no error" });
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  React.useEffect(() => {
    const fetchCourse = async () => {
      try {
        if (!eventinfo || Object.keys(eventinfo).length === 0) {
          const result = await getSelectedEvent(id);
          setEventInfo(result[0]);
        }
      } catch (err) {
        setError({ error: true, message: err });
      }
    };

    fetchCourse();
  }, [id]);
  const navigateToCareerApply = () => {
    navigate(`/Careers/description/apply?id=${id}&form=events`);
  };

  return (
    <div className="overflow-hidden">
      <div className="flex flex-col md:flex-row w-full gap-10 mt-5">
        <div className="flex flex-col gap-3 md:w-3/5 w-full">
          <div className="course-box background-img flex gap-4 flex-col">
            <span
              className="md:text-xl text-sm text-poppins text-nowrap"
              style={{ color: "#00B902" }}
            >
              Knowvation Learnings Pvt Ltd
            </span>
            <div className="flex flex-col gap-2">
              <span className="text-lato text-title text-nowrap">
                {eventinfo.title}
              </span>
            </div>
            <div className="flex flex-col gap-5">
              <div className="flex flex-row items-center gap-2 text-nowrap">
                <FontAwesomeIcon icon={faBuilding} />
                <span className="text-poppins md:text-lg text-sm text-gray-500">
                  Draper House
                </span>
              </div>
              <div className="flex flex-row items-center gap-2 text-nowrap">
                <FontAwesomeIcon icon={faClock} />
                <span className="text-poppins md:text-lg text-sm text-gray-500">
                  {"28th , 29th & 30th March 2024"}
                </span>
              </div>
              <div className="flex flex-row items-center justify-between m-3 gap-2 text-nowrap">
                <div className="flex flex-row items-center gap-3">
                  <FontAwesomeIcon icon={faLocationPin} />
                  <span className="text-poppins md:text-lg text-sm text-gray-500">
                    {/* {eventinfo.location} */} Hyderabad
                  </span>
                </div>
                <Button
                  // onClick={navigateToCareerApply}
                  className="text-lato"
                  style={{
                    padding: 10,
                    borderColor: "black",
                    boxShadow: "6px 8px 0px 4px #00B902",
                    width: "30%",
                    height: "100%",
                  }}
                >
                  Registrations Closed
                </Button>
              </div>
            </div>
          </div>

          {/* <div className="md:flex md:flex-row grid grid-cols-2 course-box justify-between">
            <div className="flex flex-row items-center gap-4">
              <FontAwesomeIcon icon={faUserPlus} color="#7BF27C" />
              <span className="flex flex-col text-poppins md:text-lg text-xs">
                Registered Users<span>289</span>
              </span>
            </div>

            <div className="flex flex-row items-center gap-4">
              <FontAwesomeIcon icon={faClock} color="#7BF27C" />
              <span className="flex flex-col text-poppins md:text-lg text-xs text-nowrap">
                Registration Deadline<span>12 days left</span>
              </span>
            </div>

            <div className="flex flex-row items-center gap-4">
              <FontAwesomeIcon icon={faEye} color="#7BF27C" />
              <span className="flex flex-col text-poppins md:text-lg text-xs">
                Impressions<span>289</span>
              </span>
            </div>

            <div className="flex flex-row items-center gap-4">
              <FontAwesomeIcon icon={faUsers} color="#7BF27C" />
              <span className="flex flex-col text-poppins md:text-lg text-xs">
                Team Size<span>4-5</span>
              </span>
            </div>
          </div> */}
          {/* <div className="flex flex-col course-box">
            <span className="text-poppins md:text-xl text-sm">Panalist</span>
            <div className="grid grid-cols-2 md:flex md:flex-row justify-around items-center text-wrap">
              <div className="flex flex-col items-center text-wrap">
                <Avatar
                  size={80}
                  src={<img src={Profile} alt="panalist image" />}
                />
                <span className="text-poppins">Jaya Shanker</span>
              </div>
              <div className="flex flex-col items-center text-wrap">
                <Avatar
                  size={80}
                  src={<img src={Profile} alt="panalist image" />}
                />
                <span className="text-poppins">Jaya Shanker</span>
              </div>
              <div className="flex flex-col items-center text-wrap">
                <Avatar
                  size={80}
                  src={<img src={Profile} alt="panalist image" />}
                />
                <span className="text-poppins">Jaya Shanker</span>
              </div>
              <div className="flex flex-col items-center text-wrap">
                <Avatar
                  size={80}
                  src={<img src={Profile} alt="panalist image" />}
                />
                <span className="text-poppins">Jaya Shanker</span>
              </div>
              <div className="flex flex-col items-center text-wrap">
                <Avatar
                  size={80}
                  src={<img src={Profile} alt="panalist image" />}
                />
                <span className="text-poppins">Jaya Shanker</span>
              </div>
            </div>
          </div> */}
          <div className="course-box flex flex-col">
            <span className="text-poppins md:text-xl text-lg">
              About Techknow
            </span>
            <span className="text-poppins md:text-sm text-xs">
              <div
                dangerouslySetInnerHTML={{ __html: eventinfo.content }}
              ></div>
            </span>
          </div>

          {/* <div className="course-box flex flex-col">
            <span className="text-poppins md:text-xl text-lg">
              Requirements
            </span>
            <span className="text-poppins md:text-sm text-xs">
              <FontAwesomeIcon icon={faDotCircle} color="green" /> Assist in the
              development and execution of digital marketing campaigns across
              various platforms, including social media, email marketing, and
              search engine marketing.
            </span>
          </div> */}
        </div>
        <div className="flex flex-col gap-3 w-full md:w-2/5">
          <span className="text-lato text-black md:text-lg text-sm">
            Event Gallery
          </span>
          <div className="course-box flex flex-col gap-3">
            {/* <span className="text-poppins md:text-lg text-sm text-nowrap">
              More Events
            </span>
            <div className="flex flex-row items-center gap-5">
              <div className="flex flex-row items-center gap-2 text-nowrap">
                <FontAwesomeIcon icon={faClock} />
                <span className="text-poppins md:text-lg text-xs text-gray-500">
                  {"24 May 2024 | 09AM - 12PM"}
                </span>
              </div>
              <div className="flex flex-row items-center gap-2 text-nowrap">
                <FontAwesomeIcon icon={faLocationPin} />
                <span className="text-poppins md:text-lg text-xs text-gray-500">
                  {"Hyderabad"}
                </span>
              </div>
            </div> */}
            <img src={car6} alt="Techknow 1.0" />
          </div>
        </div>
      </div>
    </div>
  );
}
