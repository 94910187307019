import React from "react";
import { Button } from "antd";
import ShadowButton from "components/buttons/shadowButton";

export default function Assingment() {
  return (
    <>
      <div>
        <div className="flex flex-row justify-between mt-10">
          <span className="text-pop md:text-xl text-sm">
            👉 All Submissions
          </span>
          <ShadowButton buttonName={"Assingment Details"} width={"20%"} />
        </div>
        <div className="grid grid-cols-2 md:flex gap-3 mt-10 md:flex-row justify-between">
          <div className="course-box flex flex-col p-4">
            <span className="text-pop md:text-lg text-sm">
              Assingment Recieved
            </span>
            <span className="text-pop md:text-lg text-sm">31</span>
          </div>
          <div className="course-box flex flex-col">
            <span className="text-pop md:text-lg text-sm">Yet to recieve</span>
            <span className="text-pop md:text-lg text-sm">31</span>
          </div>
          <div className="flex flex-col course-box">
            <span className="text-pop md:text-lg text-sm">Evaluated</span>
            <span className="text-pop md:text-lg text-sm">31</span>
          </div>
          <div className="flex flex-col course-box">
            <span className="text-pop md:text-lg text-sm">Yet to evaluate</span>
            <span className="text-pop md:text-lg text-sm">31</span>
          </div>
        </div>
        <div className="flex flex-row justify-between mt-10">
          <span className="text-pop md:text-xl text-sm">Student Id</span>
          <span className="text-pop md:text-xl text-sm">Assingment status</span>
          <span className="text-pop md:text-xl text-sm">Evaluation Status</span>
        </div>
        <div className="flex flex-col w-full mt-5">
          <div className="course-box flex flex-row justify-between">
            <span className="text-pop md:text-xl text-sm">#123</span>
            <span className="text-pop md:text-xl text-sm">-</span>
            <Button className="bg-black text-white">View</Button>
          </div>
        </div>
      </div>
    </>
  );
}
