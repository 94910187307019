import "../studentdashboard/cards/card.css";
import React from "react";
import profile from "../../images/profile.png";
import { Button, Avatar } from "antd";
import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
export default function Card({
  title,
  subtitle,
  avatarTitle,
  avatarSubTitle,
  avatarUrl,
  date,
  duration,
  type,
}) {
  const history = useNavigate();

  const handleCardClick = () => {
    if (type && type === "courses") history(`/mentor/courses`);
  };
  return (
    <>
      <div
        onClick={handleCardClick}
        className="flex flex-col card justify-between"
        style={{ width: "100%", height: "264px" }}
      >
        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-2">
            <span className="text-lato text-title">
              {title || "Tech Titans"}
            </span>
            <span className="text-lato text-sub-title">
              {subtitle || "(grade 5 & 6)"}
            </span>
          </div>
          <Button style={{ backgroundColor: "#7BF27C", borderRadius: "20px" }}>
            <span className="text-lato btn-text">IN PROGRESS</span>
          </Button>
        </div>
        <div className="flex flex-row items-center">
          <Avatar size={60} src={<img src={profile} alt="avatar" />} />
          <div className="flex flex-col ml-4">
            <span className="text-lato text-medium">
              {avatarTitle || "Mentor By"}
            </span>
            <span className="text-lato text-medium">
              {avatarSubTitle || "Yashwanth"}
            </span>
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className="flex flex-row items-center mr-3">
            <FontAwesomeIcon className="icons" icon={faCalendar} />
            <span className="text-lato text-ash-medium ml-2">
              {date || "Ended on 05 Aug 2024"}
            </span>
          </div>
          <div className="flex flex-row items-center">
            <FontAwesomeIcon className="icons" icon={faClock} />
            <span className="text-lato text-ash-medium ml-2">
              {duration || "10 weeks"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
