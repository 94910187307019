import React, { useEffect } from "react";
import { Button, Avatar } from "antd";
import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faIndianRupeeSign, faStar } from "@fortawesome/free-solid-svg-icons";
export default function OlympiadCard({
  isRegistered,
  isPaid,
  isLive,
  isCompleted,
}) {
  //console.log(courses);
  const navigate = useNavigate();

  const navigateToCourseInfo = (course) => {
    navigate("/Olympiads/info?id=1234");
  };

  const navigateToLeaderBoard = (course) => {
    navigate("/Olympiads/leaderboard?id=1234");
  };

  const navigateToResults = (course) => {
    navigate("/Olympiads/results?id=1234");
  };

  const navigateLiveRegistered = () => {
    if (isLive) navigate("/Olympiads/test?id=1234");
    if (isRegistered) navigate("/Olympiads/info?id=1234");
  };

  return (
    <>
      <div
        className="flex flex-col card justify-between"
        style={{ width: "100%", height: "264px" }}
      >
        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-2">
            <span className="text-lato text-title">{"olympiad one"}</span>
          </div>
          {/* <Button
                style={{ backgroundColor: "#7BF27C", borderRadius: "20px" }}
              >
                <span className="text-lato btn-text">{}</span>
              </Button> */}
        </div>
        <div className="flex flex-row items-center">
          {/* <Avatar size={60} src={<img src={profile} alt="avatar" />} /> */}
          <div className="flex flex-row items-center justify-between w-full">
            <span className="text-lato text-medium">{"07 Nov 2024"}</span>
            <span className="text-lato text-medium">
              {"starts at : 7:30PM"}
            </span>
            <span className="text-lato text-medium">
              {isPaid ? "Paid" : "open for all"}
            </span>
            <span className="text-lato text-medium">
              <FontAwesomeIcon icon={faIndianRupeeSign} />
              {isPaid ? "4000" : "0"}
            </span>
          </div>
        </div>
        <div className="flex flex-row items-center justify-around">
          <span className="text-lato text-gray-500">
            <FontAwesomeIcon icon={faStar} color="gold" />
            {"Ranked"}
          </span>
          <span className="text-lato text-gray-500">Computer fundamentals</span>
          <span className="text-lato text-gray-500">2 Hrs</span>
        </div>
        <div className="flex flex-row items-center">
          <div
            className="flex gap-5 flex-row justify-center"
            style={{ width: "100%" }}
          >
            {isCompleted ? (
              <>
                <Button
                  onClick={() => navigateToResults()}
                  className="text-lato md:w-3/6 w-full"
                  style={{
                    padding: 10,
                    borderColor: "black",
                    boxShadow: "6px 8px 0px 4px #FF40A5",
                  }}
                >
                  View Result
                </Button>
                {isPaid && (
                  <Button
                    onClick={() => navigateToLeaderBoard()}
                    className="text-lato md:w-3/6 w-full"
                    style={{
                      padding: 10,
                      borderColor: "black",
                      boxShadow: "6px 8px 0px 4px #FF40A5",
                    }}
                  >
                    View Leader Board
                  </Button>
                )}
              </>
            ) : (
              <Button
                onClick={() => navigateLiveRegistered()}
                className="text-lato"
                style={{
                  width: "50%",
                  padding: 10,
                  borderColor: "black",
                  boxShadow: "6px 8px 0px 4px #FF40A5",
                }}
              >
                {isLive ? "Participate" : isRegistered ? "View" : "Explore"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
