import { useAuth } from "pages/auth/authProvider";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "antd";
export function ProfileDropdown({ profile, name }) {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const logoutUser = async () => {
    const check = await logout();
    if (check) navigate("/");
  };

  return (
    <>
      <button type="button" onClick={() => setOpen(true)}>
        {/* <img
          onClick={() => setOpen(true)}
          className="profile"
          src={profile}
          style={{
            height: "50px",
            width: "50px",
            borderRadius: "50px",
            marginLeft: "40px",
          }}
          alt="avatar"
        /> */}
        {profile && profile.length === 1 ? (
          <Avatar className="bg-green-300">{profile}</Avatar>
        ) : (
          <Avatar src={profile} size={50} />
        )}
      </button>
      {open && (
        <div
          ref={ref}
          class="absolute w-60 top-[55px] z-10 right-5 px-5 py-3 text-base bg-white rounded-lg shadow border dark:border-transparent mt-5"
        >
          <ul class="space-y-3">
            <li class="font-medium">
              <span class="flex items-center transform transition-colors duration-200 border-r-4 border-transparent">
                <div class="mr-3">
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    ></path>
                  </svg>
                </div>
                {name && name}
              </span>
            </li>
            <hr class="dark:border-gray-700" />
            {/* <li class="font-medium">
              <Link
                to="/Profile"
                class="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-green-500"
              >
                <div class="mr-3">
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    ></path>
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    ></path>
                  </svg>
                </div>
                Setting
              </Link>
            </li> */}
            {/* <hr class="dark:border-gray-700" /> */}
            <li class="font-medium">
              <button
                onClick={logoutUser}
                class="flex items-center w-full transform transition-colors duration-200 border-r-4 border-transparent hover:border-red-600"
              >
                <div class="mr-3 text-red-600">
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    ></path>
                  </svg>
                </div>
                Logout
              </button>
            </li>
          </ul>
        </div>
      )}
    </>
  );
}
