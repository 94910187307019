import supabase from "./supabase";

export const getServiceProject = async () => {
  try {
    const { data, error } = await supabase
      .from("services")
      .select("*");
    if (error) {
      console.error(error);
      return;
    }
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getSingleServiceProject = async (id) => {
  try {
    console.log(id);
    const { data, error } = await supabase
      .from("services")
      .select("*")
      .eq("id", `${id}`);
    if (error) {
      console.error(error);
      return;
    }
    console.log(data);
    return data;
  } catch (err) {
    console.error(err);
  }
};
