import { createClient } from "@supabase/supabase-js";
import config from "../../config";

const supabase = createClient(
  `${config.prod_supabase_url}`,
  `${config.prod_supabase_public_key}`,
  {
    db: {
      schema: "public",
    },
    auth: {
      persistSession: true,
    },
  }
);

export default supabase;
