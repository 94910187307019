import "./courses.css";
import React, { useState, useEffect } from "react";
import CourseCard from "../cards/card";
import { useAuth } from "pages/auth/authProvider";
import { getCourses, getSelectedCourse } from "pages/supa-base-client/courses";
import { getUserRegisteredCourses } from "pages/supa-base-client/user";
import Card from "components/cards/card";
import { Button, Avatar, Result } from "antd";
import profile from "images/profile.png";
import ShadowButton from "components/buttons/shadowButton";
import { useNavigate } from "react-router-dom";
import EmptyPage from "components/emptyPage";
export default function Courses() {
  const [courses, setCourses] = useState();
  const { user } = useAuth();

  useEffect(() => {
    async function fetchcourses() {
      const courses = await getUserRegisteredCourses(user?.id);
      setCourses(courses);
      console.log(courses);
    }
    fetchcourses();
  }, [user]);
  const navigate = useNavigate();
  const handleClick = (course) => {
    navigate(`/dashboard/courses?id=${course.id}`, { state: { course } });
  };
  return (
    <>
      {courses && courses.length > 0 ? (
        <div
          className="grid grid-cols-1 place-items-center md:grid-cols-3 gap-6 m-0"
          style={{ width: "100%" }}
        >
          {courses.map((course) => (
            <Card
              title={
                <>
                  {" "}
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-col gap-2">
                      <span className="text-lato text-title">
                        {course[0].course_name}
                      </span>
                      <span className="text-lato text-sub-title">
                        {"(grade 5 & 6)"}
                      </span>
                    </div>
                    <Button
                      style={{
                        backgroundColor: "#7BF27C",
                        borderRadius: "20px",
                      }}
                    >
                      <span className="text-lato btn-text">IN PROGRESS</span>
                    </Button>
                  </div>
                </>
              }
              body={
                <>
                  <div className="flex flex-row items-center">
                    <Avatar
                      size={60}
                      src={<img src={profile} alt="avatar" />}
                    />
                    <div className="flex flex-col ml-4">
                      <span className="text-lato text-medium">
                        {"Mentor By"}
                      </span>
                      <span className="text-lato text-medium">
                        {course[0].mentor}
                      </span>
                    </div>
                  </div>
                </>
              }
              button={
                <ShadowButton
                  onclick={() => handleClick(course[0])}
                  buttonName={"View Progress"}
                />
              }
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-row justify-center w-full items-center">
          <EmptyPage />
        </div>
      )}
    </>
  );
}
