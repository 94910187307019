import "./App.css";
import "components/header/dashboardHeader.css";
import StudentDashboard from "./pages/studentdashboard/dashboard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CourseDetails from "./pages/pagescreens/courses/courseDetails";
import Profile from "./pages/profile/profile";
import SubmitAssingments from "./pages/pagescreens/Assingments/submitAssingment";
import ViewDetails from "./pages/pagescreens/Assingments/viewDetails";
import WishList from "pages/wishlist";
import Login from "./pages/auth/login";
import ProtectedView from "./pages/auth/protectedView";
import Singup from "./pages/auth/singup";
import Recording from "./pages/pagescreens/recording/recording";
import Chat from "./pages/chat/chat";
import Programs from "./pages/Programs/program";
import ProgramInfo from "./pages/Programs/programInfo";
import CoursePage from "./pages/Programs/courses/coursepage";
import PaymentPriview from "./pages/Programs/courses/paymentPreview";
import Blogs from "./pages/blogs/main";
import BlogDiscription from "./pages/blogs/blogDiscription";
import CareersOffered from "./pages/careers/careersOffered";
import CareerDescription from "./pages/careers/careerDescription";
import Services from "./pages/services/services";
import ServiceDescription from "./pages/services/serviceDescription";
import Event from "./pages/events/Event";
import EventDescription from "./pages/events/eventDescription";
import Home from "./pages/home/home";
import About from "./pages/about/about";
import Mentor from "./pages/mentors/mentor";
import MentorCourseInfo from "./pages/mentors/mentorCourseInfo";
import Assingment from "./pages/mentors/assingment";
import AssingmentDetails from "./pages/mentors/assingmentDetails";
import AuthProvider, { useAuth } from "./pages/auth/authProvider";
import OnBoarding from "./pages/auth/onboarding";
import Cart from "pages/cart";
import PaymentStatus from "./pages/paymentStatus/paymentStatus";
import PrivacyPolicy from "./pages/policypages/privacyPolicy";
import TermsAndConditions from "./pages/policypages/termsAndConditions";
import RefundPolicy from "./pages/policypages/refundPolicy";
import Contact from "./pages/forms/contact";
import NotFound from "./components/NotFound";
import Footer from "./components/footer/footer";
import HomeHeader from "./components/header/homeHeader";
import Header from "./components/header";
import PaymentPreview from "./pages/Programs/courses/paymentPreview";
import CounterProvider from "pages/context/counterContext";
import OlympiadHome from "pages/olympiads/home/olympiadHome";
import { useNavigate } from "react-router-dom";
function App() {
  return (
    <div className="m-5">
      <Router>
        <Header />
        <div className="min-h-96">
          <Routes>
            {/* Protected Routes */}
            <Route element={<ProtectedView />}>
              <Route path="/dashboard" element={<StudentDashboard />} />

              <Route path="/dashboard/courses" element={<CourseDetails />} />
              <Route path="/profile" element={<Profile />} />
              <Route
                path="/assingment_submission"
                element={<SubmitAssingments />}
              />
              <Route path="/chat" element={<Chat />} />
              <Route
                path="/dashboard/courses/recordings"
                element={<Recording />}
              />
              <Route path="/mysubmissions" element={<ViewDetails />} />
              <Route path="/mentor" element={<Mentor />} />
              <Route path="/mentor/info" element={<MentorCourseInfo />} />
              <Route path="/mentor/assingment" element={<Assingment />} />
              <Route
                path="/mentor/assingment/details"
                element={<AssingmentDetails />}
              />
              <Route path="/onboarding" element={<OnBoarding />} />
            </Route>

            <Route path="/categories" element={<Programs />} />
            <Route path="/courses">
              <Route index element={<ProgramInfo />} />
              <Route path="details" element={<CoursePage />} />
            </Route>
            <Route path="/Careers">
              <Route index element={<CareersOffered />} />
              <Route path="description" element={<CareerDescription />} />
            </Route>
            <Route path="/Blogs">
              <Route index element={<Blogs />} />
              <Route path="description" element={<BlogDiscription />} />
            </Route>
            <Route path="/Services">
              <Route index element={<Services />} />
              <Route path="description" element={<ServiceDescription />} />
            </Route>
            <Route path="/Events">
              <Route index element={<Event />} />
              <Route path="description" element={<EventDescription />} />
            </Route>
            <Route path="/Olympiads" element={<OlympiadHome />} />
            <Route path="/login" element={<Login />} />
            <Route path="/singup" element={<Singup />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/wishlist" element={<WishList />} />
            <Route path="/status" element={<PaymentStatus />} />
            <Route path="/checkout" element={<PaymentPreview />} />
            <Route path="/aboutus" element={<About />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/refund" element={<RefundPolicy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <footer>
          <Footer />
        </footer>
      </Router>
    </div>
  );
}

export default App;
