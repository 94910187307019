import "./courseDetails.css";
import React, { useState, useEffect } from "react";
import { faTowerBroadcast } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActiveTab from "./activeTab";
import CourseBox from "./coursebox";
import { useSearchParams } from "react-router-dom";
import { getSelectedCourse } from "pages/supa-base-client/courses";
import { getCourseModule } from "pages/supa-base-client/courses";
import { useAuth } from "pages/auth/authProvider";
import { getCurrentModule } from "pages/supa-base-client/user";

export default function CourseDetails() {
  const [selectActive, setSelectActive] = React.useState({
    module_id: "",
  });

  const [searchParams] = useSearchParams();
  const [id, setId] = React.useState(searchParams.get("id") || "combats");
  let { course: courseProp } = location.state || {};
  const [course, setCourse] = React.useState(courseProp || {});

  useEffect(() => {
    const fetchCourse = async () => {
      if (!course || Object.keys(course).length === 0) {
        const result = await getSelectedCourse(id);
        setCourse(result[0]);
      }
    };

    fetchCourse();
  }, [id]);

  const [courseModule, setCourseModule] = React.useState(null);
  const [currentModule, setCurrentModule] = React.useState(null);
  useEffect(() => {
    async function fetchModuleData() {
      const result = await getCourseModule(course.id, true);
      const current_module = await getCurrentModule(course.id);
      console.log(result);
      if (current_module && current_module.length > 0) {
        const curr_module = result.filter(
          (module) => module.id === current_module[0]?.current_module
        );
        setCurrentModule(curr_module[0]);
      }

      setCourseModule(result);
    }
    fetchModuleData();
  }, [course]);

  return (
    <>
      <div className="flex md:flex-row flex-col main-course mt-5 justify-between">
        <div className="flex flex-col left-course-bar items-start">
          <span className="text-lato">👉 Tech Titans Crash course</span>
          {courseModule &&
            courseModule.map((module) => (
              <>
                <CourseBox module={module} setSelectActive={setSelectActive} />;
                {selectActive?.module_id === module.id &&
                  currentModule >= module.order && (
                    <div className="active-div-tab right-course-bar">
                      <ActiveTab module={module} />
                    </div>
                  )}
              </>
            ))}
        </div>
        <div className="flex flex-col right-course-bar">
          <div className="flex justify-end">
            <div className="live-div flex flex-row gap-2 items-center w-44">
              <FontAwesomeIcon icon={faTowerBroadcast} />
              Go To Live
            </div>
          </div>
          {courseModule &&
            courseModule.map((module) => (
              <>
                {currentModule.order >= module.order
                  ? selectActive?.module_id === module.id && (
                      <div>
                        <ActiveTab module={module} />
                      </div>
                    )
                  : currentModule.id === module.id && (
                      <div>
                        <ActiveTab module={module} />
                      </div>
                    )}
              </>
            ))}
        </div>
      </div>
    </>
  );
}
