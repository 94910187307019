import React from "react";
import { Form, Input, Button } from "antd";

export default function AccountSettings() {
  return (
    <>
      <Form layout="vertical">
        <Form.Item label="Name" className="text-lato">
          <Input placeholder="Enter your user name" />
        </Form.Item>
        <Form.Item label="Email" className="text-lato">
          <Input placeholder="Enter your email" />
        </Form.Item>
        <Form.Item label="Change Password" className="text-lato">
          <div className="flex flex-row gap-4">
            <Input placeholder="Enter your new password" />
            <Button
              style={{ backgroundColor: "#7BF27C" }}
              className="text-lato"
            >
              Change
            </Button>
          </div>
        </Form.Item>
        <Form.Item>
          <div className="flex justify-center">
            <Button
              className="text-lato"
              style={{
                width: "80%",
                padding: 10,
                borderColor: "black",
                boxShadow: "10px 10px 0px 4px #7BF27C",
              }}
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}
