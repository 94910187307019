import React from "react";
import { SmileOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";
const EmptyPage = ({ message }) => (
  <Result
    icon={<SmileOutlined />}
    title={message || "Oops! No courses to show."}
    extra={
      <Link to="/Courses?category=combats">
        <Button type="primary">Explore Courses</Button>
      </Link>
    }
  />
);
export default EmptyPage;
