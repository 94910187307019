import React, { useEffect, useState } from "react";
import DashBoardHeader from "../../components/header/dashboardHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import BlogsCard from "./blogsCard";
import { getBlogs } from "../supa-base-client/blogs";
import HomeHeader from "../../components/header/homeHeader";

export default function Blogs() {
  const [blogsData, setBlogsData] = React.useState(null);
  const [error, setError] = useState({ error: false, message: "no error" });
  useEffect(() => {
    async function fetchBLogData() {
      try {
        const blogData = await getBlogs();
        setBlogsData(blogData);
      } catch (err) {
        setError({ error: true, message: err });
      }
    }
    fetchBLogData();
  }, []);
  return (
    <>
      <div>
        <div className="flex flex-row mt-5 w-full items-center">
          <span className="text-lato flex flex-row md:text-2xl text-sm w-full justify-center">
            Blogs
          </span>
          {/* <div
            className="flex justify-end items-end"
            style={{
              boxShadow: "2px 2px 10px 5px #00000014",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </div> */}
        </div>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-10 mt-5">
          {blogsData && blogsData.map((blog) => <BlogsCard blog={blog} />)}
        </div>
      </div>
    </>
  );
}
