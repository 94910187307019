import React, { useState } from "react";
import OlympiadCard from "./olympiadsCard";
import { Button } from "antd";
export default function OlympiadHome() {
  const [selected, setSelected] = useState("all");
  const navigationBtn = (screen) => {
    setSelected(screen);
  };
  return (
    <>
      <div className="flex flex-row md:pt-10 p-10"></div>
      <div
        className="flex flex-row navigation-buttons justify-between"
        style={{ width: "95%" }}
      >
        <Button
          onClick={() => navigationBtn("all")}
          className="btn"
          style={{
            backgroundColor: selected === "all" ? "#7BF27C" : "white",
          }}
        >
          <span className="text-poppins btn-text">All</span>
        </Button>

        <Button
          onClick={() => navigationBtn("free")}
          className="btn"
          style={{
            backgroundColor: selected === "free" ? "#7BF27C" : "white",
          }}
        >
          <span className="text-poppins btn-text">Free</span>
        </Button>
        <Button
          onClick={() => navigationBtn("paid")}
          className="btn"
          style={{
            backgroundColor: selected === "paid" ? "#7BF27C" : "white",
          }}
        >
          <span className="text-poppins btn-text">Paid</span>
        </Button>
        {/* <Button
          onClick={() => navigationBtn("completed")}
          className="btn"
          style={{
            backgroundColor: selected === "completed" ? "#7BF27C" : "white",
          }}
        >
          <span className="text-poppins btn-text">completed</span>
        </Button>
        <Button
          onClick={() => navigationBtn("ongoing")}
          className="btn"
          style={{
            backgroundColor: selected === "ongoing" ? "#7BF27C" : "white",
          }}
        >
          <span className="text-poppins btn-text">On Going</span>
        </Button> */}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:p-10 pt-10">
        {selected === "all" && (
          <>
            <OlympiadCard isPaid={false} />
            <OlympiadCard isPaid={true} />
          </>
        )}
        {selected === "free" && <OlympiadCard isPaid={false} />}
        {selected === "paid" && <OlympiadCard isPaid={true} />}
        {/* {selected === "completed" && (
          <OlympiadCard isCompleted={true} isPaid={true} />
        )}
        {selected === "ongoing" && <OlympiadCard isLive={true} />} */}
      </div>
    </>
  );
}
