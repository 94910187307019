import React, { useState, useEffect, useRef } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import ChatDashBoard from "./chatDashboard";

import ChatPage from "./chatPage";
import HomeHeader from "components/header/homeHeader";
export default function Chat() {
  const [connection, setConnection] = useState(null);
  const [chat, setChat] = useState([]);
  const latestChat = useRef(null);

  latestChat.current = chat;

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl("https://localhost:5000/hubs/chat")
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          console.log("Connected!");

          connection.on("ReceiveMessage", (message) => {
            const updatedChat = [...latestChat.current];
            updatedChat.push(message);

            setChat(updatedChat);
          });
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [connection]);

  const sendMessage = async (user, message) => {
    const chatMessage = {
      user: user,
      message: message,
    };

    if (connection.connectionStarted) {
      try {
        await connection.send("SendMessage", chatMessage);
      } catch (e) {
        console.log(e);
      }
    } else {
      alert("No connection to server yet.");
    }
  };
  return (
    <div className="flex flex-col">
      <div className="flex mt-10" style={{ height: "100vh" }}>
        <div
          className="flex border-2 border-gray-300 p-3 rounded-md"
          style={{ height: "80%" }}
        >
          <div className="flex flex-row">
            <div className="flex md:w-3/12 w-full">
              <ChatDashBoard />
            </div>
            <div className="flex md:w-3/4 w-full">
              <ChatPage sendMessage={sendMessage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
