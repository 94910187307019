import React, { useEffect, useState } from "react";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";
import ShadowButton from "components/buttons/shadowButton";
import { getEvents } from "../supa-base-client/events";
import Card from "components/cards/card";

export default function Event() {
  const [events, setEvents] = React.useState([]);
  const [error, setError] = useState({ error: true, message: "no error" });
  const navigate = useNavigate();
  useEffect(() => {
    async function getEvent() {
      try {
        const result = await getEvents();
        setEvents(result);
      } catch (err) {
        setError({ error: true, message: err });
      }
    }
    getEvent();
  }, []);

  const navigateToeventntInfo = (eventntinfo) => {
    navigate(`/events/description?id=${eventntinfo.id}`, {
      state: { eventntinfo },
    });
  };
  return (
    <>
      <div>
        <span className="text-poppins mt-5 md:text-xl text-sm flex flex-row justify-center">
          Events
        </span>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          {events &&
            events.map((event) => (
              <Card
                title={
                  <span className="text-lato text-title">{event.title}</span>
                }
                body={
                  <div className="flex flex-col gap-5">
                    <span className="text-poppins md:text-lg text-sm text-gray-500 flex flex-row items-center gap-2">
                      <FontAwesomeIcon icon={faClock} />{" "}
                      {"28th , 29th & 30th March 2024"}
                    </span>
                    <span className="text-lato text-gray-500 flex flex-row items-center gap-2">
                      <FontAwesomeIcon icon={faLocationPin} />
                      {/* {event.location} */}
                      Draper House, Hyderabad
                    </span>
                  </div>
                }
                button={
                  <ShadowButton
                    width={"50%"}
                    buttonName={"Explore"}
                    onclick={() => navigateToeventntInfo(event)}
                  />
                }
              />
            ))}
        </div>
      </div>
    </>
  );
}
