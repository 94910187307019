import React, { useEffect, useState } from "react";
import coursetemp from "images/coursetemp.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTag,
  faIndianRupeeSign,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { CaliculateDiscount } from "../../util/caliculatePrice";
import { Link, useNavigate } from "react-router-dom";
import { useCounter } from "../context/counterContext";
import EmptyPage from "../../components/emptyPage";

export default function Cart() {
  const { cartItems, removeItem, cartCount } = useCounter();

  const discount_price = CaliculateDiscount(1499, 10);
  const navigate = useNavigate();

  console.log(cartItems);

  const goToPayment = (course) => {
    navigate(`/checkout?id=${course.id}`, {
      state: { course },
    });
  };
  return (
    <>
      {cartItems.length !== 0 ? (
        <div className="flex flex-col mt-10 justify-center items-center">
          <div className="md:hidden w-full mb-4">
            <Link to="/dashboard">
              <Button style={{ width: "100%" }}>Go to Dashboard</Button>
            </Link>
          </div>
          <span className="text-lato p-4 text-left">
            {cartCount || 0} Courses in your Cart
          </span>
          {cartItems &&
            cartItems.map((course) => (
              <div className="assingment-submit-box mt-3">
                <div className="flex md:flex-row flex-col gap-2 justify-center mt-4">
                  <img
                    className="w-full sm:w-3/4 md:w-1/2 h-auto"
                    src={coursetemp}
                    alt="course_image"
                  />
                  <div className="flex flex-col gap-2">
                    <span className="text-lato text-title">
                      {course.course_name}
                    </span>
                    <span className="text-lato text-sub-title">
                      {course.grade}
                    </span>
                    <div className="flex flex-col">
                      <span className=".text-ash-medium">{"Mentor By"}</span>
                      <span className="text-lato text-medium">
                        {course.mentor}
                      </span>
                    </div>
                    <div className="flex flex-row gap-3">
                      <span className="text-lato">
                        <FontAwesomeIcon icon={faTag} />
                        <FontAwesomeIcon icon={faIndianRupeeSign} />
                        {course && course.price}
                      </span>
                      <span
                        className="text-lato"
                        style={{
                          textDecoration: "line-through",
                          color: "gray",
                        }}
                      >
                        <FontAwesomeIcon icon={faIndianRupeeSign} />
                        {course && course.original_price}
                      </span>
                      {/* <span className="text-lato" style={{ color: "#FF9900" }}>
                        10% off
                      </span> */}
                    </div>
                  </div>
                </div>
                <div
                  className="flex flex-row justify-center gap-5 mt-5 mb-3"
                  style={{ width: "100%" }}
                >
                  <Button
                    onClick={() => goToPayment(course)}
                    className="text-lato"
                    style={{
                      width: "45%",
                      padding: 10,
                      borderColor: "black",
                      boxShadow: "6px 6px 0px 4px #3970FF",
                    }}
                  >
                    <ShoppingCartOutlined />
                    Checkout
                  </Button>
                  <Button
                    onClick={() => removeItem(course.id, "cart")}
                    className="text-lato"
                    style={{
                      width: "45%",
                      padding: 10,
                      borderColor: "black",
                      boxShadow: "6px 6px 0px 4px #FF0000",
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    Remove
                  </Button>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <EmptyPage />
      )}
    </>
  );
}
