import React, { useEffect } from "react";
import Card from "components/cards/card";
import { useNavigate } from "react-router-dom";
import { Avatar } from "antd";
import profile from "images/profile.png";
import ShadowButton from "components/buttons/shadowButton";
export default function Programs() {
  const navigate = useNavigate();
  const navigateToPrograminfo = (category) => {
    navigate(`/Courses?category=${category}`);
  };
  return (
    <>
      <div>
        <span className="text-lato text-center flex justify-center mt-10 md:text-2xl">
          Courses Offered
        </span>
        <div className="flex md:m-10 m-2">
          <div
            style={{ width: "100%" }}
            className="grid grid-cols-1 md:grid-cols-3 gap-6 m-0 w-full"
          >
            <Card
              title={
                <>
                  <span className="text-lato md:text-xl text-sm">combats</span>
                  <span className="text-lato md:text-lg text-xs">
                    Grade 10 and above
                  </span>
                </>
              }
              body={
                <>
                  <Avatar size={60} src={<img src={profile} alt="avatar" />} />
                  <div className="flex flex-col ml-4">
                    <span className="text-lato text-medium">{"Mentor By"}</span>
                    <span className="text-lato text-medium">{"tarun"}</span>
                  </div>
                </>
              }
              button={
                <ShadowButton
                  buttonName={"Explore"}
                  onclick={() => navigateToPrograminfo("combats")}
                  width={"50%"}
                />
              }
            />
            <Card
              title={
                <>
                  <span className="text-lato md:text-xl text-sm">
                    Tech Titans
                  </span>
                  <span className="text-lato md:text-lg text-xs">
                    Grade 5 and 6
                  </span>
                </>
              }
              body={
                <>
                  <Avatar size={60} src={<img src={profile} alt="avatar" />} />
                  <div className="flex flex-col ml-4">
                    <span className="text-lato text-medium">{"Mentor By"}</span>
                    <span className="text-lato text-medium">{"tarun"}</span>
                  </div>
                </>
              }
              button={
                <ShadowButton
                  buttonName={"Explore"}
                  onclick={() => navigateToPrograminfo("titans")}
                  width={"50%"}
                />
              }
            />
            <Card
              title={
                <>
                  <span className="text-lato md:text-xl text-sm">
                    code ninjas
                  </span>
                  <span className="text-lato md:text-lg text-xs">
                    Grade 10 and above
                  </span>
                </>
              }
              body={
                <>
                  <Avatar size={60} src={<img src={profile} alt="avatar" />} />
                  <div className="flex flex-col ml-4">
                    <span className="text-lato text-medium">{"Mentor By"}</span>
                    <span className="text-lato text-medium">{"tarun"}</span>
                  </div>
                </>
              }
              button={
                <ShadowButton
                  buttonName={"Explore"}
                  onclick={() => navigateToPrograminfo("ninjas")}
                  width={"50%"}
                />
              }
            />{" "}
            <Card
              title={
                <>
                  <span className="text-lato md:text-xl text-sm">
                    HI-Tech Warriors
                  </span>
                  <span className="text-lato md:text-lg text-xs">
                    Grade 10 and above
                  </span>
                </>
              }
              body={
                <>
                  <Avatar size={60} src={<img src={profile} alt="avatar" />} />
                  <div className="flex flex-col ml-4">
                    <span className="text-lato text-medium">{"Mentor By"}</span>
                    <span className="text-lato text-medium">{"tarun"}</span>
                  </div>
                </>
              }
              button={
                <ShadowButton
                  buttonName={"Explore"}
                  onclick={() => navigateToPrograminfo("warriors")}
                  width={"50%"}
                />
              }
            />
            <Card
              title={
                <>
                  <span className="text-lato md:text-xl text-sm">
                    Tech Geniuses
                  </span>
                  <span className="text-lato md:text-lg text-xs">
                    Grade 10 and above
                  </span>
                </>
              }
              body={
                <>
                  <Avatar size={60} src={<img src={profile} alt="avatar" />} />
                  <div className="flex flex-col ml-4">
                    <span className="text-lato text-medium">{"Mentor By"}</span>
                    <span className="text-lato text-medium">{"tarun"}</span>
                  </div>
                </>
              }
              button={
                <ShadowButton
                  buttonName={"Explore"}
                  onclick={() => navigateToPrograminfo("geniuses")}
                  width={"50%"}
                />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
