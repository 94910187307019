import React, { Children } from "react";
import { useAuth } from "./authProvider";
import { Navigate, Outlet } from "react-router-dom";

export default function ProtectedView() {
  const { isAuthenticated } = useAuth();
  console.log(isAuthenticated);
  if (!isAuthenticated) return <Navigate to="/login" />;
  return <Outlet />;
}
