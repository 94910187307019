import React from "react";
import "./dashboardHeader.css";
import logo from "images/KnowvationIcon.jpeg";
import profile from "images/profile.png";
import mobileLogo from "images/mobileIcon.jpeg";
import { Space, Badge, Button, Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  faComment,
  faBell,
  faHeart,
} from "@fortawesome/free-regular-svg-icons";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import DropDown from "../dropdown/dropDown";
import { useCounter } from "../../pages/context/counterContext";
import DrawerFun from "../drawer/Drawer";
import { ProfileDropdown } from "./profile";
import { useAuth } from "pages/auth/authProvider";
export default function DashBoardHeader() {
  const { cartCount, wishCount } = useCounter();
  const { user } = useAuth();
  const [open, setOpen] = React.useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  return (
    <>
      <header>
        <div className="flex flex-row justify-between items-center">
          <div className="basis-1/4 flex flex-row gap-10 items-center">
            <Link to="/">
              <img
                className="web-logo"
                src={"/logonew.png"}
                alt="logo knowvation"
              />
              <img
                className="mobile-logo"
                src={"/logolight.png"}
                alt="logo knowvation"
              />
            </Link>
            <Button href="/dashboard" className="dashboard-header-btn">
              Dashboard
            </Button>
          </div>
          <div className="basis-1/2 flex flex-row justify-end gap-10 items-center">
            <div className="drop-down">
              <Space wrap>
                <DropDown />

                {/* <a href="www.knowvationlearnings.in">
                  <span className="text-poppins">Icube</span>
                </a> */}
              </Space>
            </div>
            <div className="flex flex-row gap-6 items-center">
              {/* <Link to="/chat">
                <FontAwesomeIcon className="icons" icon={faComment} />
              </Link> */}
              {/* <FontAwesomeIcon className="icons" icon={faBell} /> */}
              <Link to="/wishlist">
                <Badge count={`${wishCount}`} color="#7BF27C">
                  <FontAwesomeIcon className="icons" icon={faHeart} />
                </Badge>
              </Link>

              <Badge count={`${cartCount}`} color="#7BF27C">
                <Link to="/cart">
                  <ShoppingCartOutlined className="icons" />
                </Link>
              </Badge>

              <ProfileDropdown
                profile={user && user.avatar}
                name={user && user.name}
              />
              <FontAwesomeIcon
                onClick={showDrawer}
                className="icons menu"
                icon={faBars}
              />
            </div>
          </div>
          <DrawerFun setOpen={setOpen} open={open} />
        </div>
      </header>
    </>
  );
}
