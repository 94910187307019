import React, { useEffect } from "react";
import courseCover from "images/coursetemp.png";
import { Input, Select, Card } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faComment,
  faHeart,
  faSearch,
  faShare,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { faCommentAlt, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { getRecentBlogs, getSelectedBlog } from "../supa-base-client/blogs";
import HomeHeader from "../../components/header/homeHeader";
export default function BlogDiscription() {
  const { Meta } = Card;

  const location = useLocation();
  const [recentBlogs, setRecentBlogs] = React.useState(null);
  const [error, setError] = React.useState({
    error: false,
    message: "no error",
  });
  let { blog: blogProp } = location.state || {};
  const [blog, setBlog] = React.useState(blogProp || {});
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  React.useEffect(() => {
    const fetchCourse = async () => {
      if (!blog || Object.keys(blog).length === 0) {
        try {
          const result = await getSelectedBlog(id);

          setBlog(result[0]);
        } catch (err) {
          setError({ error: true, message: err });
        }
      }
    };

    fetchCourse();
  }, [id]);
  useEffect(() => {
    async function getRecent() {
      try {
        const result = await getRecentBlogs(id);
        setRecentBlogs(result);
      } catch (err) {
        setError({ error: true, message: err });
      }
    }
    getRecent();
  }, [blog]);

  const navigate = useNavigate();

  const navigateToBlogDescription = (blog) => {
    navigate(`/Blogs/description?id=${blog.id}`, { state: { blog } });
    setBlog(blog);
  };

  return (
    <>
      <div>
        <div className="flex flex-col md:flex-row mt-5 gap-4 w-full">
          <div className="flex gap-3 flex-col md:w-9/12 w-full">
            {/* <div className="flex-col gap-5 md:hidden flex w-full">
              <Input
                className="text-poppins text-black h-12"
                placeholder="Search Catagories"
                suffix={<FontAwesomeIcon icon={faSearch} />}
              />
              <Select
                className="h-12"
                defaultValue="Select Catagory"
                style={{ width: "100%", color: "black" }}
                options={[{ value: "lucy", label: "Lucy" }]}
              />
            </div> */}
            <img
              className="md:w-3/6 w-full"
              src={blog.image}
              alt="blog image"
            />
            <span
              className="text-poppins md:text-sm text-xs"
              style={{ color: "#00B902" }}
            >
              09 May 2024 |{" "}
              <span style={{ color: "black" }}>{blog.domain}</span>
            </span>
            <span className="text-lato md:text-xl text-sm">{blog.title}</span>
            <span className="text-lato md:text-xl text-sm">
              {blog.description}
            </span>
            <span className="course-box text-poppins md:text-sm text-xs">
              {blog.content}
            </span>
            {/* <div className="flex flex-row justify-between items-center">
              <span className="text-lato md:text-xl text-lg">
                Read Next Blog <FontAwesomeIcon icon={faArrowRight} />
              </span>
              <div className="flex flex-row items-center gap-10">
                <div
                  className="flex justify-center items-center"
                  style={{
                    backgroundColor: "black",
                    padding: "10px",
                    borderRadius: "50%",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    color="white"
                    style={{ fontSize: "20px" }}
                  />
                </div>
                <div
                  className="flex justify-center items-center"
                  style={{
                    backgroundColor: "black",
                    padding: "10px",
                    borderRadius: "50%",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCommentAlt}
                    color="white"
                    style={{ fontSize: "20px" }}
                  />
                </div>
                <div
                  className="flex justify-center items-center"
                  style={{
                    backgroundColor: "black",
                    padding: "10px",
                    borderRadius: "50%",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faShareNodes}
                    color="white"
                    style={{ fontSize: "20px" }}
                  />
                </div>
              </div>
            </div> */}
          </div>
          <div className="flex gap-3 flex-col md:w-3/12 w-full">
            {/* <Input
              className="text-poppins md:flex hidden text-black h-12"
              placeholder="Search Catagories"
              suffix={<FontAwesomeIcon icon={faSearch} />}
            />
            <Select
              className="md:flex hidden"
              defaultValue="Select Catagory"
              style={{ width: "100%", color: "black" }}
              options={[{ value: "lucy", label: "Lucy" }]}
            /> */}
            <span
              className="text-poppins md:text-xl text-sm"
              style={{ color: "black" }}
            >
              Recent Blogs
            </span>
            {recentBlogs &&
              recentBlogs.map((blog) => (
                <div onClick={() => navigateToBlogDescription(blog)} className="flex flex-row course-box gap-3">
                  <img
                    alt="example"
                    style={{ width: "30%", height: "100%" }}
                    src={blog.image}
                  />
                  <div>
                    <Meta
                      title={
                        <span
                          className="text-poppins md:text-sm text-xs"
                          style={{ color: "#00B902" }}
                        >
                          09 May 2024 |{" "}
                          <span style={{ color: "black" }}>{blog.domain}</span>
                        </span>
                      }
                    />
                    <Meta
                      className="text-lato md:text-lg"
                      title={`${blog.title}`}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
