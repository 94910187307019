import "../pagescreens/courses/courseDetails.css";
import React from "react";
import ActiveTab from "../pagescreens/courses/activeTab";
import CourseBox from "../pagescreens/courses/coursebox";
import BatchCard from "./batchCard";

export default function MentorCourseInfo() {
  const [selectActive, setSelectActive] = React.useState({
    module_id: "",
  });
  const [id, setId] = React.useState("");
  return (
    <>
      <div className="grid md:grid-cols-3 grid-cols-2 mt-10">
        <BatchCard />
      </div>
      <div className="flex md:flex-row flex-col main-course mt-5 justify-between">
        <div className="flex flex-col left-course-bar items-start">
          <span className="text-lato">👉 Tech Titans Crash course</span>
          <CourseBox id="1" setSelectActive={setSelectActive} />
          {selectActive?.module_id === "1" && (
            <div className="active-div-tab right-course-bar">
              <ActiveTab />
            </div>
          )}
          <CourseBox id="2" setSelectActive={setSelectActive} />
          {selectActive?.module_id === "2" && (
            <div className="active-div-tab right-course-bar">
              <ActiveTab />
            </div>
          )}
          <CourseBox setSelectActive={setSelectActive} />
        </div>
        <div className="flex flex-col right-course-bar">
          {selectActive?.module_id && (
            <div>
              <ActiveTab />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
