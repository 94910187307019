import React from "react";
import { Result, Button } from "antd";
import HomeHeader from "./header/homeHeader";
import Footer from "./footer/footer";

export default function NotFound() {
  return (
    <>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button href="/" type="primary">
            Back Home
          </Button>
        }
      />
      <Footer />
    </>
  );
}
