import { Avatar, Button } from "antd";
import "./profile.css";
import React from "react";
import profile from "../../images/profile.png";
import { EditOutlined } from "@ant-design/icons";
import AccountSettings from "./Forms/accountSettings";
import HomeHeader from "components/header/homeHeader";
export default function Profile() {
  const [selected, setSelected] = React.useState("Account Settings");
  return (
    <>
      <div className="flex justify-center mt-10">
        <span className="profile-lato text-center">Profile Settings</span>
      </div>
      <div className="flex md:flex-row flex-col justify-between md:p-10 gap-7">
        <div className="md:basis-1/4 flex flex-col course-box">
          <div className="flex flex-col pl-10 pr-10 pt-2 pb-2 gap-5">
            <Button
              onClick={() => setSelected("Account Settings")}
              style={{
                backgroundColor:
                  selected === "Account Settings" ? "#7BF27C" : "",
              }}
              className="settings-btn"
            >
              <span style={{ width: "100%", textAlign: "left" }}>
                Account Settings
              </span>
            </Button>
            <Button
              onClick={() => setSelected("Notification Preferences")}
              style={{
                backgroundColor:
                  selected === "Notification Preferences" ? "#7BF27C" : "",
              }}
              className="settings-btn"
            >
              <span style={{ width: "100%", textAlign: "left" }}>
                Notification Preferences
              </span>
            </Button>
            <Button
              onClick={() => setSelected("PrivacySecurity")}
              style={{
                backgroundColor:
                  selected === "PrivacySecurity" ? "#7BF27C" : "",
              }}
              className="settings-btn"
            >
              <span style={{ width: "100%", textAlign: "left" }}>
                Privacy & Security
              </span>
            </Button>
            <Button
              onClick={() => setSelected("SupportBilling")}
              style={{
                backgroundColor: selected === "SupportBilling" ? "#7BF27C" : "",
              }}
              className="settings-btn"
            >
              <span style={{ width: "100%", textAlign: "left" }}>
                Support & Billing{" "}
              </span>
            </Button>
            <Button
              onClick={() => setSelected("language")}
              style={{
                backgroundColor: selected === "language" ? "#7BF27C" : "",
              }}
              className="settings-btn"
            >
              <span style={{ width: "100%", textAlign: "left" }}>
                Language{" "}
              </span>
            </Button>
            <Button
              onClick={() => setSelected("appearenceAccessebility")}
              style={{
                backgroundColor:
                  selected === "appearenceAccessebility" ? "#7BF27C" : "",
              }}
              className="settings-btn"
            >
              <span style={{ width: "100%", textAlign: "left" }}>
                Appearence and Accessebility
              </span>
            </Button>
          </div>
        </div>
        <div className="basis-1/2 flex flex-col md:ml-5 md:mr-5">
          {selected === "Account Settings" && <AccountSettings />}
        </div>
        <div className="basis-1/4 flex flex-col gap-5 items-center">
          <div
            className="flex flex-col justify-center items-center"
            style={{ width: "150px" }}
          >
            <Avatar size={100} src={<img src={profile} alt="avatar" />} />
            <Button
              icon={<EditOutlined />}
              size={"medium"}
              style={{ width: "150px", fontFamily: "Poppins", fontWeight: 600 }}
            >
              Change Pic
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
