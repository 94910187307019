import supabase from "./supabase";

export const getEvents = async () => {
  try {
    const { data, error } = await supabase
      .from("events")
      .select("*")
      .eq("is_public", true);
    if (error) {
      console.error(error);
      return;
    }
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getSelectedEvent = async (id) => {
  try {
    const { data, error } = await supabase
      .from("events")
      .select("*")
      .eq("is_public", true)
      .eq("id", `${id}`);
    if (error) {
      console.error(error);
      return;
    }
    return data;
  } catch (err) {
    console.error(err);
  }
};
