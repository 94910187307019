import React from "react";
import "./activeTab.css";
import { Button } from "antd";
import All from "./all";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import {
  faMicrophone,
  faTowerBroadcast,
} from "@fortawesome/free-solid-svg-icons";
import Assingments from "../Assingments/assingment";

export default function ActiveTab({ module }) {
  const [selected, setSelected] = React.useState("all");
  const navigationBtn = (screen_name) => {
    setSelected(screen_name);
  };
  const [moduleinfo, setModuleInfo] = React.useState(module);
  return (
    <>
      <div className="course-box flex flex-col mt-5">
        <span className="text-lato" style={{ color: "#000000B2" }}>
          Module {moduleinfo && moduleinfo.order}
        </span>
        <span className="text-lato mt-2">
          {moduleinfo && moduleinfo.module_name}
        </span>

        <div
          className="flex flex-row justify-between mt-3 overflow-x-auto"
          style={{ width: "100%" }}
        >
          <Button
            onClick={() => navigationBtn("all")}
            className="btn"
            style={{
              backgroundColor: selected === "all" ? "#7BF27C" : "white",
              minWidth: "120px",
              margin: "0px 10px 0px 0px",
            }}
          >
            <span className="btn-active-course-text">All(3)</span>
          </Button>

          <Button
            onClick={() => navigationBtn("live_events")}
            className="btn"
            style={{
              backgroundColor: selected === "live_events" ? "#7BF27C" : "white",
              minWidth: "120px",
              margin: "0px 10px 0px 0px",
            }}
          >
            <span className="btn-active-course-text">Live Events(1)</span>
          </Button>
          <Button
            onClick={() => navigationBtn("materials")}
            className="btn"
            style={{
              backgroundColor: selected === "materials" ? "#7BF27C" : "white",
              minWidth: "120px",
              margin: "0px 10px 0px 0px",
            }}
          >
            <span className="btn-active-course-text">Materials(1)</span>
          </Button>
          <Button
            onClick={() => navigationBtn("recordings")}
            className="btn"
            style={{
              backgroundColor: selected === "recordings" ? "#7BF27C" : "white",
              minWidth: "120px",
              margin: "0px 10px 0px 0px",
            }}
          >
            <span className="btn-active-course-text">Recordings(1)</span>
          </Button>
          <Button
            onClick={() => navigationBtn("assingments")}
            className="btn"
            style={{
              backgroundColor: selected === "assingments" ? "#7BF27C" : "white",
              minWidth: "120px",
              margin: "0px 10px 0px 0px",
            }}
          >
            <span className="btn-active-course-text">Assingments(1)</span>
          </Button>
        </div>

        {selected === "all" && (
          <>
            <All
              iconname={faTowerBroadcast}
              title="Live Class"
              subtitle="First Program in Java"
              btnContent="On Going"
            />
            <All
              iconname={faFile}
              title="PDF"
              subtitle="First Program in Java"
              btnContent="View"
              btnColor="black"
              contentColor="white"
            />
            <All
              iconname={faMicrophone}
              title="Recording"
              subtitle="First Program in Java"
              btnContent="View"
              btnColor="black"
              contentColor="white"
            />
            <Assingments />
          </>
        )}
        {selected === "assingments" && <Assingments />}
        {selected === "live_events" && (
          <All iconname={faTowerBroadcast} btnContent="On Going" />
        )}
        {selected === "materials" && (
          <All
            iconname={faFile}
            title="PDF"
            subtitle="First Program in Java"
            btnContent="View"
            btnColor="black"
            contentColor="white"
          />
        )}
        {selected === "recordings" && (
          <All
            iconname={faMicrophone}
            title="Recording"
            subtitle="First Program in Java"
            btnContent="View"
            btnColor="black"
            contentColor="white"
          />
        )}
      </div>
    </>
  );
}
