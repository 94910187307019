import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate, getEmploymentType, getRoleType } from "util/methods";
import { useNavigate } from "react-router-dom";
import { getCareers } from "../supa-base-client/careers";
import Card from "components/cards/card";
import {
  faIndianRupeeSign,
  faLocation,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import ShadowButton from "components/buttons/shadowButton";
import { message } from "antd";
export default function CareersOffered() {
  const [careers, setCareers] = useState([]);
  const [error, setError] = useState({ error: true, message: "no error" });
  const navigate = useNavigate();
  const navigateToCareerInfo = (career) => {
    navigate(
      `/Careers/description?id=${career.id}&category=${career.role_type}`,
      {
        state: { career },
      }
    );
  };
  useEffect(() => {
    async function getCareer() {
      try {
        const result = await getCareers();
        setCareers(result);
      } catch (err) {
        setError({ error: true, message: err });
      }
    }
    getCareer();
  }, []);
  return (
    <>
      <div>
        <span className="text-lato md:text-xl text-sm flex flex-row justify-center items-center mt-5">
          Career Offered
        </span>
        <div className="grid md:grid-cols-3 grid-cols-1 mt-5 gap-4">
          {careers &&
            careers.map((career) => (
              <Card
                title={
                  <>
                    <span className="text-lato text-title">
                      {career.career_name}
                    </span>
                    <span className="text-lato text-sub-title">
                      {getEmploymentType(career.emplyment_type)}
                    </span>
                  </>
                }
                bottom={
                  <>
                    <div>
                      <FontAwesomeIcon icon={faLocation} />
                      <span className="text-poppins md:text-lg text-sm text-gray-500">
                        {getRoleType(career.role_type)}
                      </span>
                    </div>
                    <div>
                      <span className="text-lato text-gray-500">
                        No of Openings : {career.openings}
                      </span>
                    </div>
                  </>
                }
                button={
                  <ShadowButton
                    buttonName={"Explore"}
                    onclick={() => navigateToCareerInfo(career)}
                    width={"50%"}
                  />
                }
              />
            ))}
        </div>
      </div>
    </>
  );
}
