import React from "react";
import "../courses/activeTab.css";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider } from "antd";
import { Link } from "react-router-dom";
export default function Assingments({ assingment }) {
  return (
    <div className="course-box flex flex-col mt-3">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-3">
          <FontAwesomeIcon icon={faFile} />
          <div className="flex flex-col">
            <span className="active-box-heading">Assingment</span>
            <span className="active-box-subheading">
              {assingment || "First program in Java"}
            </span>
          </div>
        </div>
        <div className="flex flex-col items-start">
          <span className="active-lato">Deadline</span>
          {/* <span
            className="active-lato"
            style={{
              backgroundColor: "#DFDFDF",
              padding: "10px 15px 10px 15px",
              borderRadius: "10px",
            }}
          >
            14 Mar 11:59 PM
          </span> */}
        </div>
      </div>
      <Divider
        plain
        style={{ color: "#00000029", borderWidth: "2px" }}
      ></Divider>
      <div className="flex flex-row justify-center items-center gap-5">
        <Link style={{ width: "40%" }} to="/mysubmissions">
          <Button
            style={{
              width: "100%",
              padding: "20px 0px 20px 0px",
              borderRadius: "10px",
            }}
            className="active-lato"
          >
            View Details
          </Button>
        </Link>
        <Link style={{ width: "40%" }} to="/assingment_submission">
          <Button
            className="active-lato"
            style={{
              width: "100%",
              backgroundColor: "black",
              color: "white",
              padding: "20px 0px 20px 0px",
              borderRadius: "10px",
            }}
          >
            Submit Assingment
          </Button>
        </Link>
      </div>
    </div>
  );
}
