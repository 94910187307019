import "./events.css";
import React from "react";
import EventCard from "../cards/eventCard";
import EmptyPage from "components/emptyPage";
export default function Events() {
  return (
    <>
      <div
        className="grid grid-cols-1 md:grid-cols-3 gap-6 m-0"
        style={{ width: "100%" }}
      >
        {/* <EventCard title="Tecknow 1.0" subtitle="Event" /> */}
      </div>
    </>
  );
}
