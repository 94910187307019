import { Calendar } from "antd";
import React, { useEffect, useState } from "react";
import supabase from "pages/supa-base-client/supabase";
export default function AboutCourse({ courseid }) {
  const [courseMeta, setCourseMeta] = useState({});
  useEffect(() => {
    async function courseMetaData() {
      try {
        console.log(courseid);
        const { data, error } = await supabase
          .from("course_meta_data")
          .select("*")
          .eq("course_id", courseid);
        if (error) {
          console.error(error);
        }

        setCourseMeta(data[0]);
      } catch (err) {
        console.error(err);
      }
    }
    courseMetaData();
  }, [courseid]);
  return (
    <>
      <div id="about" className="mt-4 flex flex-col">
        <span className="text-poppins md:text-lg text-sm">About Course</span>
        <span className="text-poppins md:text-sm text-xs text-gray-500">
          {(courseMeta && courseMeta.about_course) ||
            "Contineous evaluation in field of computer science and every one needs to be updated here we are making that impact with updated and latest course contents with real world examples to understand the concepts easy."}
        </span>
      </div>
    </>
  );
}
